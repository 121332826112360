import React, { memo, useState } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import { View } from "react-native";
import Template, { StateDialog } from "components/Template";
import { KcTextInput } from "components/KcFormInput";
import { Button, Portal, Text } from "react-native-paper";
import { BaseDomainError, checkIdentity, requestOTP } from "api";
import { Toast } from "react-native-toast-message/lib/src/Toast";
type KcContext_PhoneOtpLogin = Extract<
  KcContext,
  { pageId: "hues-login-phone-otp.ftl" }
>;

const PhoneOtpLogin = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_PhoneOtpLogin; i18n: I18n } & KcProps) => {
    const [phone, setPhone] = useState<string | null>(kcContext.phone ?? null);
    const [step, setStep] = useState(kcContext.phone != null ? 2 : 1);
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={true}
        maxWidth={515}
        backLabel={
          step > 1 && kcContext.phone == null
            ? "Nhập số điện thoại"
            : "Quay lại"
        }
        onBack={() => {
          if (step > 1 && kcContext.phone == null) {
            setStep(1);
          } else {
            window.location.href = kcContext.url.loginRestartFlowUrl;
          }
        }}
        // headerNode={kcContext.auth?.attemptedUsername}
        headerNode={""}
        formNode={
          <>
            {step == 2 && (
              <OTPForm
                loginAction={kcContext.url.loginAction}
                isAppInitiatedAction={kcContext.isAppInitiatedAction}
                phone={phone ?? ""}
              />
            )}
            <View style={{ display: step == 1 ? "flex" : "none" }}>
              <PhoneInput
                onSubmit={(phone) => {
                  setPhone(phone);
                  setStep(2);
                }}
              />
            </View>
          </>
        }
      />
    );
  }
);

const OTPForm = (props: {
  phone: string;
  loginAction: string;
  isAppInitiatedAction: boolean;
}) => {
  const [otp, setOTP] = React.useState("");
  const [cancel, setCancel] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef<HTMLFormElement>(null);
  return (
    <View>
      <form ref={formRef} action={props.loginAction} method="post">
        <input hidden name="otp" value={otp} />
        <input hidden name="phone" value={props.phone} />
        {cancel && (
          <input
            type="checkbox"
            id="cancel-aia"
            name="cancel-aia"
            checked
            value={"on"}
            style={{ display: "none" }}
            tabIndex={-1}
          />
        )}
      </form>
      <OTPInput
        disabled={loading}
        phone={props.phone}
        submitLabel={"Gửi"}
        onSubmit={(otp) => {
          setOTP(otp);
          setLoading(true);
          setTimeout(() => {
            formRef.current?.submit();
          }, 100);
        }}
      />
      {props.isAppInitiatedAction && (
        <Button
          textColor="#757575"
          disabled={loading}
          style={{ marginTop: 8 }}
          onPress={() => {
            setCancel(true);
            setLoading(true);
            setTimeout(() => {
              formRef.current?.submit();
            }, 60);
          }}
        >
          {"Hủy"}
        </Button>
      )}
      <Portal>
        {loading && <StateDialog state={"loading"} visible={true} />}
      </Portal>
    </View>
  );
};
export const PhoneInput = (props: { onSubmit: (phone: string) => void }) => {
  const [phone, setPhone] = React.useState("");
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);

  return (
    <View>
      <View style={{ alignContent: "center", alignItems: "center" }}>
        <Text variant="titleSmall" style={{ textAlign: "center" }}>
          Tài khoản chưa có thông tin số điện thoại. Vui lòng cập nhật số điện
          thoại cho tài khoản.
        </Text>
      </View>
      <KcTextInput
        require={true}
        tabIndex={4}
        maxLength={10}
        placeholder="Nhập số di động"
        helper="Mã xác thực OTP sẽ gửi về số này"
        title="Số điện thoại"
        onChangeText={(value) => {
          setPhone(value.trim());
        }}
        keyboardType="numeric"
      />
      <View
        style={{
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginTop: 8,
        }}
      ></View>
      <Button
        // disabled={state != null || otp.length < 6 || props.disabled}
        onPress={async () => {
          setState({
            loading: true,
          });
          try {
            var res = await checkIdentity(phone, []);
            if (res["phone"]) {
              setState({
                loading: false,
                message: "Số điện thoại đã được đăng ký",
              });
            } else {
              setState(null);
              props.onSubmit(phone);
            }
          } catch (e) {
            var error = "";
            if (e instanceof BaseDomainError) {
              error = e.message;
            } else {
              error = "Có lỗi xảy ra vui lòng thử lại";
            }
            setState({
              loading: false,
              message: error,
            });
          }
        }}
        style={{ marginTop: 24 }}
        mode="contained"
      >
        {"Cập nhật".toUpperCase()}
      </Button>
      <Portal>
        {state != null && (
          <StateDialog
            onCancel={
              state?.loading == true
                ? undefined
                : () => {
                    setState(null);
                  }
            }
            state={state?.loading == true ? "loading" : "alert"}
            visible={true}
            message={state?.message ?? ""}
          />
        )}
      </Portal>
    </View>
  );
};
export const OTPInput = (props: {
  submitLabel?: string | null;
  disabled: boolean;
  phone: string;
  onSubmit: (otp: string) => void;
}) => {
  const [otp, setOTP] = React.useState("");
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);

  React.useEffect(() => {
    requestOTP(props.phone);
  }, []);

  return (
    <View>
      <View style={{ alignContent: "center", alignItems: "center" }}>
        <Text variant="titleSmall" style={{ textAlign: "center" }}>
          Vui lòng nhập mã xác thực được gửi tới số điện thoại {props.phone}. Có
          thể mất một khoảng thời gian để nhận mã.
        </Text>
      </View>
      <KcTextInput
        first={false}
        disabled={state != null && !props.disabled}
        maxLength={6}
        keyboardType={"numeric"}
        title={"Mã OTP"}
        onChangeText={(text) => {
          setOTP(text);
        }}
      />
      <View
        style={{
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginTop: 8,
        }}
      >
        <Text variant="bodyMedium">Chưa nhận được mã OTP?</Text>
        <Button
          disabled={state != null || props.disabled}
          contentStyle={{ height: 24, marginLeft: -4 }}
          onPress={async () => {
            setState({
              loading: true,
            });
            try {
              await requestOTP(props.phone);
              setState(null);
              setTimeout(() => {
                Toast.show({
                  text1: "Yêu cầu OTP thành công",
                  position: "top",
                  topOffset: 80,
                });
              }, 100);
            } catch (e) {
              var error = "";
              if (e instanceof BaseDomainError) {
                error = e.message;
              } else {
                error = "Có lỗi xảy ra vui lòng thử lại";
              }
              setState({
                loading: false,
                message: error,
              });
            }
          }}
        >
          Gửi lại
        </Button>
      </View>
      <Button
        disabled={state != null || props.disabled}
        onPress={() => {
          if (otp.length < 6) {
            Toast.show({
              text1: "Vui lòng nhập đủ 6 số",
              position: "top",
              type: "error",
              topOffset: 80,
            });
          }else{
            props.onSubmit(otp);
          }
        }}
        style={{ marginTop: 24 }}
        mode="contained"
      >
        {(props.submitLabel ?? "Đăng nhập").toUpperCase()}
      </Button>
      <Portal>
        {state != null && (
          <StateDialog
            onCancel={
              state?.loading == true
                ? undefined
                : () => {
                    setState(null);
                  }
            }
            state={state?.loading == true ? "loading" : "alert"}
            visible={true}
            message={state?.message ?? ""}
          />
        )}
      </Portal>
    </View>
  );
};

export default PhoneOtpLogin;
