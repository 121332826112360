import "./KcApp.css";
import { lazy, Suspense } from "react";
import type { KcContext } from "./kcContext";
import KcAppBase, { defaultKcProps } from "keycloakify";
import { useI18n } from "./i18n";
import Login from "./Login";
import PhoneLoginSelectUser from "./PhoneLoginSelectUser";
import LoginUpdatePassword from "./LoginUpdatePassword";

const PhoneOtpLogin = lazy(() => import("./PhoneOTPLogin"));
const Register = lazy(() => import("./Register"));
const HuesCheckCitizenInfo = lazy(() => import("./HuesCheckCitizenInfo"));
const Terms = lazy(() => import("./Terms"));
const MyExtraPage1 = lazy(() => import("./MyExtraPage1"));
const MyExtraPage2 = lazy(() => import("./MyExtraPage2"));
const AuthSelectionLogin = lazy(() => import("./AuthSelectionLogin"));
const HuesLoginIdpReviewUser = lazy(() => import("./HuesLoginIdpReviewUser"));
//@ts-ignore
export const isMobileApp = window.ReactNativeWebView != null;
export type Props = {
  kcContext: KcContext;
};

export default function KcApp({ kcContext }: Props) {
  const i18n = useI18n({ kcContext });

  //NOTE: Locales not yet downloaded
  if (i18n === null) {
    return null;
  }

  const props = {
    i18n,
    ...defaultKcProps,
    // NOTE: The classes are defined in ./KcApp.css
    kcHeaderWrapperClass: "my-color my-font",
  };

  return (
    <Suspense>
      {(() => {
        switch (kcContext.pageId) {
          case "hues-login-check-citizen-info.ftl":
            return <HuesCheckCitizenInfo {...{ kcContext, ...props }} />;
          case "register.ftl":
            return <Register {...{ kcContext, ...props }} />;
          case "login.ftl":
            return <Login {...{ kcContext, ...props }} />;
          case "login-update-password.ftl":
            return <LoginUpdatePassword {...{ kcContext, ...props }} />;
          case "hues-login-phone-otp.ftl":
            return <PhoneOtpLogin {...{ kcContext, ...props }} />;
          case "hues-login-auth-selection.ftl":
            return <AuthSelectionLogin {...{ kcContext, ...props }} />;
          case "hues-phone-login-select-user.ftl":
            return <PhoneLoginSelectUser {...{ kcContext, ...props }} />;
          case "hues-login-idp-review-user.ftl":
            return <HuesLoginIdpReviewUser {...{ kcContext, ...props }} />;
          case "terms.ftl":
            return <Terms {...{ kcContext, ...props }} />;
          case "my-extra-page-1.ftl":
            return <MyExtraPage1 {...{ kcContext, ...props }} />;
          case "my-extra-page-2.ftl":
            return <MyExtraPage2 {...{ kcContext, ...props }} />;
          default:
            return <KcAppBase {...{ kcContext, ...props }} />;
        }
      })()}
    </Suspense>
  );
}
