import React, { memo } from "react";
import type { KcContextBase, KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import { Pressable, View } from "react-native";
import Template, { StateDialog } from "components/Template";
import { KcTextInput } from "components/KcFormInput";
import { Button, Portal, Text } from "react-native-paper";
import { BaseDomainError, requestOTP } from "api";
import { Toast } from "react-native-toast-message/lib/src/Toast";

const LoginUpdatePassword = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: {
    kcContext: KcContextBase.LoginUpdatePassword;
    i18n: I18n;
  } & KcProps) => {
    const { msg, msgStr } = i18n;
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={true}
        maxWidth={520}
        // onBack={
        //   !kcContext.isAppInitiatedAction
        //     ? () => {
        //         window.location.href = kcContext.url.loginRestartFlowUrl;
        //       }
        //     : undefined
        // }
        // backLabel={kcContext.isAppInitiatedAction ? "Quay lại" : "Đăng nhập"}
        // headerNode={kcContext.auth?.attemptedUsername}
        headerNode={"Đổi mật khẩu"}
        formNode={
          <Form
            loginUrl={kcContext.url.loginAction}
            username={kcContext.username}
            isAppInitiatedAction={kcContext.isAppInitiatedAction}
          />
        }
      />
    );
  }
);

const Form = (props: {
  loginUrl: string;
  username: string;
  isAppInitiatedAction: boolean;
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [newPassword, setNewPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmError, setConfirmError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);

  return (
    <View>
      <form
        id="kc-form-login"
        ref={formRef}
        action={props.loginUrl}
        method="post"
      >
        <input
          type="text"
          id="username"
          name="username"
          value={props.username}
          readOnly={true}
          autoComplete="username"
          style={{ display: "none" }}
        />
        <input
          type="password"
          id="password"
          name="password"
          autoComplete="current-password"
          style={{ display: "none" }}
        />
        <input
          type="password"
          id="password-new"
          name="password-new"
          autoComplete="new-password"
          tabIndex={-1}
          value={newPassword}
          style={{ display: "none" }}
        />
        <input
          type="password"
          id="password-confirm"
          name="password-confirm"
          autoComplete="new-password"
          value={newPassword}
          style={{ display: "none" }}
          tabIndex={-1}
        />
        {props.isAppInitiatedAction && (
          <input
            type="checkbox"
            id="logout-sessions"
            name="logout-sessions"
            style={{ display: "none" }}
            tabIndex={-1}
          />
        )}
        {cancel && (
          <input
            type="checkbox"
            id="cancel-aia"
            name="cancel-aia"
            checked
            value={"on"}
            style={{ display: "none" }}
            tabIndex={-1}
          />
        )}
      </form>
      <KcTextInput
        tabIndex={1}
        first={true}
        onChangeText={(value) => {
          setPassword(value);
        }}
        onKeyPress={(e) => {}}
        title={"Mật khẩu mới"}
        secureTextEntry={true}
      />
      <KcTextInput
        tabIndex={2}
        onChangeText={(value) => {
          setConfirmPassword(value);
        }}
        onKeyPress={(e) => {}}
        title={"Xác nhận mật khẩu mới"}
        error={confirmError}
        errorMsg={"Mật khẩu xác nhận không trùng khớp"}
        secureTextEntry={true}
      />

      <View style={{ marginTop: 32 }}>
        <Button
          disabled={loading}
          onPress={() => {
            if (
              password.trim().length == 0 ||
              confirmPassword.trim().length == 0
            ) {
              setConfirmError(false);
              Toast.show({
                text1: "Vui lòng nhập đủ thông tin",
                position: "top",
                type: "info",
                topOffset: 80,
              });
            } else {
              if (password != confirmPassword) {
                setConfirmError(true);
              } else {
                setConfirmError(false);
                setNewPassword(password);
                setLoading(true);
                setTimeout(() => {
                  formRef.current?.submit();
                }, 60);
              }
            }
          }}
          mode="contained"
        >
          {"Đổi mật khẩu".toUpperCase()}
        </Button>
        {props.isAppInitiatedAction && (
          <Button
            textColor="#757575"
            disabled={loading}
            style={{ marginTop: 8 }}
            onPress={() => {
              setCancel(true);
              setLoading(true);
              setTimeout(() => {
                formRef.current?.submit();
              }, 60);
            }}
          >
            {"Hủy"}
          </Button>
        )}
      </View>
      <Portal>
        <StateDialog state="loading" visible={loading} />
      </Portal>
    </View>
  );
};

export default LoginUpdatePassword;
