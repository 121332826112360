import React, { memo, useEffect, useState } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import { Pressable, View } from "react-native";
import Template, { StateDialog } from "components/Template";
import { KcTextInput } from "components/KcFormInput";
import { Button, Portal, Text } from "react-native-paper";
import { BaseDomainError, requestOTP } from "api";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { OTPInput } from "./PhoneOTPLogin";
import { InfoNode } from "./Login";
type KcContext_IdpReview = Extract<
  KcContext,
  { pageId: "hues-login-idp-review-user.ftl" }
>;

const HuesLoginIdpReviewUser = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_IdpReview; i18n: I18n } & KcProps) => {
    const formRef = React.useRef<HTMLFormElement>(null);
    const [loading, setLoading] = React.useState(false);
    const [pass, setPass] = React.useState("");
    const temp = kcContext.users.filter(
      (e) => e.username == (kcContext.username ?? "")
    );
    const [user, setUser] = React.useState<UserType | null>(
      temp.length > 0 ? temp[0] : null
    );
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={true}
        maxWidth={520}
        onBack={() => {
          if (user == null) {
            window.location.href = kcContext.url.loginRestartFlowUrl;
          } else {
            setUser(null);
          }
        }}
        backLabel={user == null ? "Đăng nhập" : "Quay lại"}
        headerNode={"Chọn tài khoản"}
        formNode={
          <>
            <form
              id="kc-form-login"
              ref={formRef}
              action={kcContext.url.loginAction}
              method="post"
            >
              <input
                type={"hidden"}
                name="username"
                readOnly
                value={user?.username ?? ""}
              />
              <input
                type={"hidden"}
                name="authType"
                readOnly
                value={user?.authType ?? ""}
              />
              <input type={"hidden"} name="pass" readOnly value={pass ?? ""} />
            </form>
            {(user == null || user!.authType == null) && (
              <UserList
                idpName={kcContext.idpName}
                users={kcContext.users}
                onSelectUser={(user) => {
                  setUser(user);
                  if (user.authType == null) {
                    setLoading(true);
                    setTimeout(() => {
                      formRef.current?.submit();
                    }, 100);
                  }
                }}
              />
            )}
            {user != null && user.authType != null && (
              <AuthFormMemo
                onSubmit={(pass) => {
                  setPass(pass);
                  setLoading(true);
                  setTimeout(() => {
                    formRef.current?.submit();
                  }, 100);
                }}
                user={user}
              />
            )}
            <Portal>
              <StateDialog state="loading" visible={loading} />
            </Portal>
          </>
        }
        infoNode={<InfoNode />}
      />
    );
  }
);

const UserList = (props: {
  idpName: string;
  onSelectUser: (user: UserType) => void;
  users: UserType[];
}) => {
  return (
    <View>
      <Text style={{}}>
        Danh sách tài khoản Hue-S đã được đăng ký bằng số CMND/CCCD của tài
        khoản <b>{props.idpName}</b> mà bạn vừa đăng nhập. Bạn cần chọn tài
        khoản Hue-S ở lần đăng nhập đầu tiên để thực hiện liên thông dữ liệu
        giữa tài khoản <b>{props.idpName}</b> và tài khoản Hue-S.
      </Text>
      <View style={{ marginTop: 16 }}>
        {props.users.map((e) => {
          return (
            <UserItem
              key={e.username}
              data={e}
              onSelectUser={props.onSelectUser}
            />
          );
        })}
      </View>
    </View>
  );
};

const AuthForm = (props: {
  onSubmit: (pass: string) => void;
  user: UserType;
}) => {
  return (
    <div>
      <OTPInput
        phone={props.user.phone!}
        disabled={false}
        onSubmit={props.onSubmit}
      />
    </div>
  );
};

const AuthFormMemo = memo(AuthForm);

const UserItem = (props: {
  data: UserType;
  onSelectUser: (user: UserType) => void;
}) => {
  const [hover, setHover] = React.useState(false);
  return (
    <Pressable
      onHoverIn={() => {
        setHover(true);
      }}
      onHoverOut={() => {
        setHover(false);
      }}
      onPress={() => {
        props.onSelectUser(props.data);
      }}
    >
      <View
        style={{
          borderWidth: 1,
          padding: 12,
          borderRadius: 8,
          marginBottom: 8,
          borderColor: "#e6e6e6",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: hover ? "#ededed" : undefined,
        }}
      >
        <img
          src={
            props.data.avatar ??
            "https://dichvu.huecity.vn/uploads/avatar_Img_2567a79da0_4acaf52c08.png"
          }
          alt="avatar"
          width="80px"
          height="80px"
          style={{
            marginRight: "12px",
            backgroundColor: "white",
            borderRadius: 45,
          }}
        />
        <View>
          <Text>
            <b>Họ tên:</b> {props.data.name ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Số điện thoại:</b> {props.data.phone ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Số CMND:</b> {props.data.identityCardId ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Số CCCD:</b> {props.data.citizenId ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Ngày sinh</b>: {props.data.birthdate ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Giới tính</b>: {props.data.gender ? props.data.gender : ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Lần cập nhật cuối:</b> {props.data.updatedAt}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

type UserType = {
  username: string;
  name: string | null;
  updatedAt: string;
  createdAt: string;
  avatar: string | null;
  birthdate: string | null;
  phone: string | null;
  gender: string | null;
  citizenId: string | null;
  identityCardId: string | null;
  authType: "otp" | "password" | null;
};

export default HuesLoginIdpReviewUser;
