import React, { useState, memo } from "react";
import type { I18n } from "./i18n";
import { TouchableOpacity, View } from "react-native";
import {
  Button,
  Checkbox,
  Text,
  Provider as PaperProvider,
  MD3LightTheme as DefaultTheme,
  Portal,
} from "react-native-paper";
import { BaseDomainError, checkPhoneRegistered, checkQR } from "api";
import { KcTextInput } from "components/KcFormInput";
import { StateDialog } from "components/Template";
const PhoneOtpLoginForm = (props: {
  onBack: () => void;
  setLoading: (loading: boolean) => void;
  disabled: boolean;
  phone?: string;
  lockValue?: boolean;
  onSubmit: () => void;
}) => {
  const [phone, setPhone] = React.useState("");
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);
  return (
    <View>
      <input tabIndex={-1} hidden name="username" value={""} />
      <input tabIndex={-1} hidden name="phone" value={phone} />
      <Text variant="bodyMedium" style={{ textAlign: "center" }}>
        Vui lòng nhập số điện thoại bạn đã sử dụng đăng ký tài khoản SSO. Mã
        xác thực OTP sẽ được gửi về số điện thoại này.
      </Text>
      <KcTextInput
        // disabled={props.disabled || props.lockValue}
        maxLength={10}
        // defaultValue={props.phone}
        keyboardType={"numeric"}
        onChangeText={(value) => {
          setPhone(value);
        }}
        title="Điện thoại"
      />
      <Button
        disabled={props.disabled}
        onPress={() => {
          setState({
            loading: true,
          });
          setTimeout(() => {
            checkPhoneRegistered(phone)
              .then((result) => {
                if (result) {
                  setState(null);
                  setTimeout(() => {
                    props.onSubmit();
                  }, 100);
                } else {
                  setState({
                    loading: false,
                    message: "Số điện thoại " + phone + " chưa đăng ký Hue-S",
                  });
                }
              })
              .catch((e: Error) => {
                var error = "";
                if (e instanceof BaseDomainError) {
                  error = e.message;
                } else {
                  error = "Có lỗi xảy ra vui lòng thử lại";
                }
                setState({
                  loading: false,
                  message: error,
                });
              });
          }, 120);
        }}
        style={{ marginTop: 20 }}
        mode="contained"
      >
        {"Lấy mã OTP".toUpperCase()}
      </Button>
      {state != null && (
        <Portal>
          <StateDialog
            visible={true}
            state={state?.loading == true ? "loading" : "alert"}
            onCancel={
              state?.loading == true
                ? undefined
                : () => {
                    setState(null);
                  }
            }
            message={state?.message ?? ""}
          />
        </Portal>
      )}
    </View>
  );
};
export default PhoneOtpLoginForm;
