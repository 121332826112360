import React, { memo } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import { Pressable, View } from "react-native";
import Template, { StateDialog } from "components/Template";
import { KcTextInput } from "components/KcFormInput";
import { Button, Portal, Text } from "react-native-paper";
import { BaseDomainError, requestOTP } from "api";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { InfoNode } from "./Login";
type KcContext_PhoneOtpLogin = Extract<
  KcContext,
  { pageId: "hues-phone-login-select-user.ftl" }
>;

const PhoneLoginSelectUser = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_PhoneOtpLogin; i18n: I18n } & KcProps) => {
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={true}
        maxWidth={520}
        onBack={() => {
          window.location.href = kcContext.url.loginRestartFlowUrl;
        }}
        backLabel={"Đăng nhập"}
        // headerNode={kcContext.auth?.attemptedUsername}
        headerNode={"Chọn tài khoản"}
        formNode={
          <Form
            loginUrl={kcContext.url.loginAction}
            phone={kcContext.phone}
            users={kcContext.users}
          />
        }
        infoNode={<InfoNode />}
      />
    );
  }
);

const Form = (props: {
  loginUrl: string;
  phone: string;
  users: {
    username: string;
    name: string | null;
    lastUpdated: string | null;
    avatar: string | null;
    birthDate: string | null;
    gender: number | null;
    citizenId: string | null;
    identityCardId: string | null;
    // maskedPaperId: string | null;
  }[];
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [username, setUsername] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  return (
    <View>
      <form
        id="kc-form-login"
        ref={formRef}
        action={props.loginUrl}
        method="post"
      >
        <input type={"hidden"} name="username" readOnly value={username} />
      </form>
      <Text style={{}}>
        Các tài khoản dưới đây đều sử dụng chung số điện thoại{" "}
        <b>{props.phone}</b>. Vui lòng chọn tài khoản mà bạn đang sử dụng. Các
        tài khoản không được chọn sẽ bị xoá thông tin số điện thoại và tạm khoá.
      </Text>
      <View style={{ marginTop: 16 }}>
        {props.users.map((e) => {
          return (
            <UserItem
              data={e}
              onSelect={(username) => {
                setUsername(username);
                setLoading(true);
                setTimeout(() => {
                  formRef.current?.submit();
                }, 150);
              }}
            />
          );
        })}
      </View>
      <Portal>
        <StateDialog state="loading" visible={loading} />
      </Portal>
    </View>
  );
};

const UserItem = (props: {
  data: {
    username: string;
    name: string | null;
    avatar: string | null;
    lastUpdated: string | null;
    birthDate: string | null;
    gender: number | null;
    citizenId: string | null;
    identityCardId: string | null;
    // maskedPaperId: string | null;
  };
  onSelect: (username: string) => void;
}) => {
  const [hover, setHover] = React.useState(false);
  return (
    <Pressable
      onHoverIn={() => {
        setHover(true);
      }}
      onHoverOut={() => {
        setHover(false);
      }}
      onPress={() => {
        props.onSelect(props.data.username);
      }}
    >
      <View
        style={{
          borderWidth: 1,
          padding: 12,
          borderRadius: 8,
          marginBottom: 8,
          borderColor: "#e6e6e6",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: hover ? "#ededed" : undefined,
        }}
      >
        <img
          src={
            props.data.avatar ??
            "https://dichvu.huecity.vn/uploads/avatar_Img_2567a79da0_4acaf52c08.png"
          }
          alt="avatar"
          width="80px"
          height="80px"
          style={{
            marginRight: "12px",
            backgroundColor: "white",
            borderRadius: 45,
          }}
        />
        <View>
          <Text>
            <b>Họ tên:</b> {props.data.name ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Ngày sinh</b>: {props.data.birthDate ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Giới tính</b>:{" "}
            {props.data.gender ? (props.data.gender == 1 ? "Nam" : "Nữ") : ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Số CMND:</b> {props.data.identityCardId ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Số CCCD:</b> {props.data.citizenId ?? ""}
          </Text>
          <Text style={{ marginTop: 4 }}>
            <b>Lần cập nhật cuối:</b> {props.data.lastUpdated ?? ""}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default PhoneLoginSelectUser;
