import React, { useState, memo } from "react";
import {
  GestureResponderEvent,
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  Pressable,
  TextInputKeyPressEventData,
  View,
} from "react-native";
import {
  Text,
  TextInput,
  Provider as PaperProvider,
  MD3LightTheme as DefaultTheme,
  TextInputProps,
  useTheme,
  SegmentedButtons,
  Avatar,
} from "react-native-paper";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import Select from "react-select/dist/declarations/src/Select";
import { BaseDomainError, BASE_URL } from "api";
import axios from "axios";
import Toast from "react-native-toast-message";
import { throws } from "assert";
import vi from "date-fns/locale/vi";
import moment from "moment";
registerLocale("vi", vi);
setDefaultLocale("vi");

const Label = (props: { title: string; require: boolean }) => {
  return (
    <Text style={{ marginTop: 0, fontWeight: "500" }} variant="bodyMedium">
      {props.title}
      {props.require && (
        <Text style={{ color: "red", fontWeight: "600" }}> *</Text>
      )}
    </Text>
  );
};

export const KcDateInputPicker = (
  props: {
    onChange?: (value: Date) => void | undefined;
    tabIndex?: number | undefined;
  } & BasePropsType
) => {
  var initDate: null | Date = null;
  if (props.defaultValue) {
    try {
      const temp = moment(props.defaultValue, "DD/MM/YYYY", true);
      if (temp.isValid()) {
        initDate = temp.toDate();
      }
    } catch {}
  }
  const [startDate, setStartDate] = useState<null | Date>(initDate);
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ];
  return (
    <View
      style={{
        width: "100%",
        zIndex: props.zIndex,
        marginTop: props.first ? 0 : 20,
      }}
    >
      {props.title && (
        <Label require={props.require ?? false} title={props.title} />
      )}
      <DatePicker
        fixedHeight
        calendarStartDay={1}
        showPopperArrow={false}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) =>
                changeYear(Number.parseInt(value))
              }
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        className={"Kc-calendar"}
        wrapperClassName={"Kc-w-calendar"}
        selected={startDate}
        tabIndex={props.tabIndex}
        customInput={<CustomDateInput defaultValue={props.defaultValue} />}
        onChange={(date: Date) => {
          setStartDate(date);
          if (props.onChange) {
            props.onChange(date);
          }
        }}
      />
    </View>
  );
};
const DateInput = (props: any, ref: any) => {
  console.log(props);
  var value = "";
  try {
    var data = (props.value + "").split("/");
    if (data.length == 3) {
      value = data[1] + "/" + data[0] + "/" + data[2];
    }
  } catch {}
  return (
    <KcTextInput
      onPress={() => {
        props.onClick();
      }}
      tabIndex={props.tabIndex}
      placeholder={"ngày/tháng/năm"}
      value={value}
      editable={false}
      first={true}
    />
  );
};

const CustomDateInput = React.forwardRef(DateInput);

type BasePropsType = {
  title?: string | undefined;
  require?: boolean | undefined;
  placeholder?: string | undefined;
  first?: boolean | undefined;
  zIndex?: number | undefined;
  error?: boolean | undefined;
  errorMsg?: string | undefined;
  helper?: string | undefined;
  defaultValue?: string | undefined;
};

export const KcTextInput = (
  props: {
    secureTextEntry?: boolean | undefined;
    onChangeText?: ((text: string) => void) | undefined;
    // onPress?: (() => void) | undefined;
    onPress?: null | ((event: GestureResponderEvent) => void) | undefined;
    onKeyPress?:
      | ((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void)
      | undefined;
    disabled?: boolean | undefined;
    tabIndex?: number | undefined;
    maxLength?: number | undefined;
    value?: string | undefined;
    editable?: boolean | undefined;
    keyboardType?: KeyboardTypeOptions | undefined;
  } & BasePropsType
) => {
  type InputProps = Pick<TextInputProps, "secureTextEntry" | "label">;
  const { colors } = useTheme();
  const [unsecure, setUnsecure] = React.useState(false);
  const [isHover, setHover] = React.useState(false);
  return (
    <View style={{ marginTop: props.first ? 0 : 16 }}>
      {props.title && (
        <Label
          require={props.require ?? false}
          title={props.title ?? undefined}
        />
      )}
      <Pressable
        onPress={props.onPress}
        onHoverIn={() => {
          setHover(true);
        }}
        onHoverOut={() => {
          setHover(false);
        }}
      >
        <TextInput
          ref={(r: any) => {
            if (props.tabIndex) {
              r?.setNativeProps({ tabIndex: props.tabIndex });
            } else {
              r?.setNativeProps({ tabIndex: undefined });
            }
          }}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          placeholderTextColor={"#949494"}
          value={props.value}
          editable={props.editable ?? true}
          defaultValue={props.defaultValue}
          maxLength={props.maxLength}
          keyboardType={props.keyboardType}
          disabled={props.disabled}
          mode={"outlined"}
          error={props.error}
          onChangeText={props?.onChangeText}
          outlineColor={"#cfcfcf"}
          style={{
            backgroundColor: isHover
              ? "rgba(237,237,237,0.5)"
              : "rgba(237,237,237,0.2)",
            height: 40,
            borderRadius: 4,
            borderTopEndRadius: 4,
            fontSize: 15,
          }}
          right={
            props.secureTextEntry ? (
              <TextInput.Icon
                onPress={() => {
                  setUnsecure(!unsecure);
                }}
                icon="eye"
                size={20}
                iconColor={unsecure ? colors.tertiary : "#707070"}
                style={{ marginTop: 16 }}
              />
            ) : undefined
          }
          theme={{ roundness: 4 }}
          {...(props as InputProps)}
          secureTextEntry={unsecure ? false : props.secureTextEntry}
        />
      </Pressable>
      {props.helper && !props.error && (
        <Text
          style={{
            fontSize: 13,
            marginLeft: 4,
            marginTop: 4,
            color: "#5c5c5c",
          }}
        >
          {props.helper ?? ""}
        </Text>
      )}
      {props.error && props.errorMsg && (
        <Text
          style={{
            fontSize: 13,
            marginLeft: 4,
            marginTop: 4,
            color: "red",
          }}
        >
          {props.errorMsg ?? ""}
        </Text>
      )}
    </View>
  );
};
export const KcAvatarInput = (
  props: {
    onChange?: (file: File | null) => void | undefined;
  } & BasePropsType
) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = React.useState<{
    file: File | null;
    thumbData: string | null;
  }>({
    file: null,
    thumbData: props.defaultValue ?? null,
  });
  const convertBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <View
      style={{
        marginTop: props.first ? 0 : 20,
        alignSelf: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {props.title && (
        <View style={{ marginBottom: 8 }}>
          <Label require={props.require ?? false} title={props.title} />
        </View>
      )}
      <Pressable
        onPress={() => {
          inputRef.current?.click();
        }}
      >
        <View style={{ position: "relative" }}>
          <Avatar.Image
            size={110}
            style={{
              backgroundColor: "#f1f1f1",
            }}
            source={{
              uri: avatar.thumbData ?? "",
            }}
          />
          <View
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: avatar.thumbData ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MaterialCommunityIcons
              size={32}
              color={"#a1a1a1"}
              name="file-image-plus-outline"
            />
            <Text style={{ fontSize: 12, marginTop: 4, color: "#a1a1a1" }}>
              Chọn ảnh
            </Text>
          </View>
        </View>
      </Pressable>
      <input
        ref={inputRef}
        onChange={async (event) => {
          if (event?.target?.files) {
            var file = event?.target?.files![0] as File;
            setAvatar({
              file: file,
              thumbData: await convertBase64(file),
            });
            if (props.onChange) {
              props.onChange(file);
            }
          }
          // console.log(file.);
        }}
        style={{ display: "none" }}
        accept="image/*"
        type={"file"}
      />
    </View>
  );
};
export const GenderSelection = (
  props: {
    onchange?: (value: number) => void | undefined;
  } & BasePropsType
) => {
  var defaultValue: "1" | "2" | string = "";
  if (props.defaultValue == "1") {
    defaultValue = "1";
  } else if (props.defaultValue == "2") {
    defaultValue = "2";
  }
  const [value, setValue] = React.useState(defaultValue);
  return (
    <View style={{ marginTop: props.first ? 0 : 20 }}>
      {props.title && (
        <View style={{ marginBottom: 8 }}>
          <Label require={props.require ?? false} title={props.title} />
        </View>
      )}
      <SegmentedButtons
        value={value}
        onValueChange={(newValue) => {
          setValue(newValue);
          if (props.onchange) {
            props.onchange(Number.parseInt(newValue));
          }
        }}
        buttons={[
          {
            value: "1",
            label: "Nam",
            style: { minWidth: 120 },
          },
          {
            value: "2",
            label: "Nữ",
            style: { minWidth: 120 },
          },
        ]}
      />
    </View>
  );
};

export type TitleWithId = {
  id: string;
  title: string;
};

export interface DataOption {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const KcSelectionInput = (
  props: {
    initData?: DataOption[];
    onChange?: (option: DataOption) => void;
    dataSource: SelectDataSource;
  } & BasePropsType
) => {
  return (
    <View style={{ marginTop: props.first ? 0 : 20, zIndex: props.zIndex }}>
      {props.title && (
        <Label require={props.require ?? false} title={props.title} />
      )}
      <SelectionInput
        dataSource={props.dataSource}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </View>
  );
};

export const SelectionInput = (props: {
  dataSource: SelectDataSource;
  requireMessage?: string | undefined;
  onChange?: (option: DataOption) => void;
  defaultValue?: string | undefined;
  placeholder?: string | undefined;
}) => {
  const ref = React.useRef<Select>(null);
  const [state, setState] = React.useState<{
    status: "loading" | "error" | null;
    data: DataOption[] | null;
  }>({
    status: null,
    data: props.dataSource instanceof Array ? props.dataSource : null,
  });
  const filterData = (inputValue: string, data: DataOption[]) => {
    return (data ?? []).filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const promiseOptions = (inputValue: string) => {
    if (!(props.dataSource instanceof Array) && props.dataSource.url == null) {
      return Promise.resolve([]);
    }
    if (state.status != "loading") {
      if (state.data == null) {
        setState({
          status: "loading",
          data: [],
        });
        return fetchSelectionData(props.dataSource as AsyncSource).then(
          (datas) => {
            setState({
              status: null,
              data: datas,
            });
            if (props.defaultValue) {
              datas.forEach((e) => {
                if (e.value == props.defaultValue) {
                  ref.current?.selectOption(e);
                }
              });
            }
            return filterData(inputValue, datas);
          }
        );
      } else {
        return Promise.resolve(filterData(inputValue, state.data));
      }
    } else {
      // return Promise.resolve([]);
    }
  };
  React.useEffect(() => {
    var defaultValue: DataOption | null = null;
    if (props.dataSource instanceof Array) {
      if (props.dataSource.length > 0 && props.defaultValue) {
        props.dataSource.forEach((e) => {
          if (e.value == props.defaultValue) {
            defaultValue = e;
          }
        });
      }
    }

    if (defaultValue != null) {
      ref.current?.selectOption(defaultValue);
    }
  }, []);
  const colourStyles = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#808080",
        fontWeight: "500",
      };
    },
    option: (styles: any) => {
      return {
        ...styles,
        fontSize: 14,
      };
    },
    control: (provided: any, state: any) => ({
      ...provided,
      background: "rgba(237,237,237,0.2)",
      minHeight: "42px",
      height: "42px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      color: "#000",
      fontSize: 14,
      fontWeight: "600",
    }),
  };
  return (
    <AsyncSelect
      ref={ref}
      menuPlacement="auto"
      defaultOptions
      styles={colourStyles}
      onChange={(a) => {
        var selected: any = a;
        var oldSelected: any = ref.current?.getValue()[0];
        if (
          oldSelected == undefined ||
          oldSelected["value"] != selected["value"]
        ) {
          if (props.onChange) {
            props.onChange(selected as DataOption);
          }
        }
      }}
      onFocus={() => {
        if (
          !(props.dataSource instanceof Array) &&
          props.dataSource.url == null
        ) {
          if (props.requireMessage) {
            Toast.show({
              text1: props.requireMessage,
              position: "bottom",
              bottomOffset: 120,
              visibilityTime: 2000,
            });
          }
          setTimeout(() => {
            ref.current?.blur();
          }, 10);
        }
      }}
      noOptionsMessage={(a) => "Dữ liệu trống"}
      loadingMessage={() => "Đang tải dữ liệu..."}
      isLoading={state.status == "loading"}
      placeholder={props.placeholder}
      loadOptions={promiseOptions}
    />
  );
};

export type AddressSelectionData = {
  provinceId: string | null;
  districtId: string | null;
  wardId: string | null;
  address: string | null;
};
export type AddressSelectionProps = {
  title?: string | undefined;
  require?: boolean | undefined;
  zIndex?: number | undefined;
  placeholder?: string | undefined;
  first?: boolean | undefined;
  showAddressDetail?: boolean | undefined;
  onChange?: (data: AddressSelectionData) => void | null;
  defaultValue?: AddressSelectionData | undefined;
};
export class KcAddressSelection extends React.Component<
  AddressSelectionProps,
  AddressSelectionData
> {
  constructor(props: AddressSelectionProps) {
    super(props);

    this.state = {
      provinceId: this.props.defaultValue?.provinceId ?? null,
      districtId: this.props.defaultValue?.districtId ?? null,
      wardId: this.props.defaultValue?.wardId ?? null,
      address: this.props.defaultValue?.address ?? null,
    };
  }
  render(): React.ReactNode {
    return (
      <View
        style={{
          zIndex: this.props.zIndex,
          marginTop: this.props.first ? 0 : 20,
        }}
      >
        {this.props.title && (
          <View style={{ marginBottom: 8 }}>
            <Label
              require={this.props.require ?? false}
              title={this.props.title}
            />
          </View>
        )}
        <SelectionInput
          dataSource={{
            url: BASE_URL + "/category/province",
            processor: (data: any) => {
              if (data["Success"]) {
                var options: DataOption[] = [];
                for (var item of data["Data"]) {
                  options.push({
                    value: item["id"],
                    label: item["ten"],
                  });
                }
                return options;
              } else {
                throw new BaseDomainError(data["Message"]);
              }
            },
          }}
          placeholder={"Chọn Tỉnh/Thành phố"}
          defaultValue={this.state.provinceId ?? undefined}
          onChange={(o) => {
            if (this.state.provinceId != o.value) {
              var newState = {
                provinceId: o.value,
                districtId: null,
                wardId: null,
                address: this.state.address,
              };
              this.setState(newState);
              if (this.props.onChange) {
                this.props.onChange(newState);
              }
            }
          }}
        />
        <View style={{ marginTop: 8 }} />
        <SelectionInput
          key={this.state.provinceId + "_district"}
          dataSource={{
            url:
              this.state.provinceId != null
                ? BASE_URL + "/category/district/" + this.state.provinceId
                : null,
            processor: (data: any) => {
              var options: DataOption[] = [];
              for (var item of data) {
                options.push({
                  value: (item["id"] + "").trim(),
                  label: item["name"],
                });
              }
              return options;
            },
          }}
          requireMessage={"Chưa chọn Tỉnh/Thành phố"}
          placeholder={"Chọn Quận/Huyện"}
          defaultValue={this.state.districtId ?? undefined}
          onChange={(o) => {
            if (this.state.districtId != o.value) {
              var newState = {
                provinceId: this.state.provinceId,
                districtId: o.value,
                wardId: null,
                address: this.state.address,
              };
              this.setState(newState);
              if (this.props.onChange) {
                this.props.onChange(newState);
              }
            }
          }}
        />
        <View style={{ marginTop: 8 }} />
        <SelectionInput
          key={
            (this.state.provinceId ?? "") +
            (this.state.districtId ?? "") +
            "_ward"
          }
          dataSource={{
            url:
              this.state.districtId != null
                ? BASE_URL + "/category/ward/" + this.state.districtId
                : null,
            processor: (data: any) => {
              var options: DataOption[] = [];
              for (var item of data) {
                options.push({
                  value: (item["id"] + "").trim(),
                  label: item["name"],
                });
              }
              return options;
            },
          }}
          requireMessage={"Chưa chọn Quận/Huyện"}
          placeholder={"Chọn Phường/Xã"}
          defaultValue={this.state.wardId ?? undefined}
          onChange={(o) => {
            if (this.state.wardId != o.value) {
              const newState = {
                provinceId: this.state.provinceId,
                districtId: this.state.districtId,
                wardId: o.value,
                address: this.state.address,
              };
              this.setState(newState);
              if (this.props.onChange) {
                this.props.onChange(newState);
              }
            }
          }}
        />
        {this.props.showAddressDetail && (
          <KcTextInput
            first={true}
            defaultValue={this.props.defaultValue?.address ?? ""}
            onChangeText={(value) => {
              const newState = {
                ...this.state,
                address: value,
              };
              this.setState(newState);
              if (this.props.onChange) {
                this.props.onChange(newState);
              }
            }}
            placeholder={"Nhập số nhà, toà nhà, tên đường..."}
          />
        )}
      </View>
    );
  }
}

async function fetchSelectionData(source: AsyncSource): Promise<DataOption[]> {
  while (true) {
    try {
      var res = await axios.get(source.url!);
      return source.processor(res.data);
    } catch {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
}

export type SelectDataSource = DataOption[] | AsyncSource;

type AsyncSource = {
  url?: string | null;
  processor: (data: any) => DataOption[];
};
