import React, { useReducer, useEffect, memo } from "react";
import type { ReactNode } from "react";
import type { KcContextBase } from "keycloakify/lib/getKcContext/KcContextBase";
import type { KcTemplateProps } from "keycloakify";
import type { I18n } from "keycloakify/lib/i18n";
import { useCssAndCx } from "keycloakify/lib/tools/useCssAndCx";
import { assert } from "keycloakify/lib/tools/assert";
import { useCallbackFactory } from "powerhooks/useCallbackFactory";
import { headInsert } from "keycloakify/lib/tools/headInsert";
import { pathJoin } from "keycloakify/bin/tools/pathJoin";
import { useConstCallback } from "powerhooks/useConstCallback";
import { Dimensions, Image, TouchableOpacity, View } from "react-native";
import {
  ActivityIndicator,
  Button,
  DefaultTheme,
  Dialog,
  MD2Colors,
  Paragraph,
  Portal,
  Text,
  Provider as PaperProvider,
} from "react-native-paper";
import Icon from "react-native-vector-icons/Ionicons";
import { KcTextInput } from "./KcFormInput";
import * as API from "api";
import bgImg from "../KcApp/img/keycloak-bg.png";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Toast from "react-native-toast-message";
import { KcContext } from "KcApp/kcContext";
export type TemplateProps = {
  displayInfo?: boolean;
  displayMessage?: boolean;
  displayRequiredFields?: boolean;
  displayWide?: boolean;
  onBack?: () => void | null;
  backLabel?: string | undefined;
  showAnotherWayIfPresent?: boolean;
  headerNode: ReactNode;
  width?: string | number | undefined;
  minWidth?: number | undefined;
  maxWidth?: number | undefined;
  showUsernameNode?: ReactNode;
  formNode: ReactNode;
  infoNode?: ReactNode;
  /** If you write your own page you probably want
   * to avoid pulling the default theme assets.
   */
  doFetchDefaultThemeResources: boolean;
} & { kcContext: KcContextBase; i18n: I18n } & KcTemplateProps;

const theme = {
  ...DefaultTheme,
  roundness: 1,
  version: 3,
  colors: {
    ...DefaultTheme.colors,
    primary: "#1e88e5",
    secondary: "#aa00ff",
    tertiary: "#6ec6ff",
    secondaryContainer: "#bdd6ff",
    outline: "#c4c4c4",
    error: "#ff424d",
  },
};

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};

const Template = memo((props: TemplateProps) => {
  const {
    displayInfo = false,
    displayMessage = true,
    displayRequiredFields = false,
    displayWide = false,
    showAnotherWayIfPresent = true,
    headerNode,
    showUsernameNode = null,
    formNode,
    infoNode = null,
    kcContext,
    i18n,
    doFetchDefaultThemeResources,
  } = props;

  const { cx } = useCssAndCx();

  const { msg, changeLocale, labelBySupportedLanguageTag, currentLanguageTag } =
    i18n;

  const onChangeLanguageClickFactory = useCallbackFactory(
    ([kcLanguageTag]: [string]) => changeLocale(kcLanguageTag)
  );

  const onTryAnotherWayClick = useConstCallback(
    () => (
      document.forms["kc-select-try-another-way-form" as never].submit(), false
    )
  );

  const { realm, locale, auth, url, message, isAppInitiatedAction } = kcContext;

  const [isExtraCssLoaded, setExtraCssLoaded] = useReducer(() => true, false);
  useEffect(() => {
    if (!doFetchDefaultThemeResources) {
      setExtraCssLoaded();
      return;
    }

    let isUnmounted = false;
    const cleanups: (() => void)[] = [];

    const toArr = (x: string | readonly string[] | undefined) =>
      typeof x === "string" ? x.split(" ") : x ?? [];

    Promise.all(
      [
        ...toArr(props.stylesCommon).map((relativePath) =>
          pathJoin(url.resourcesCommonPath, relativePath)
        ),
        ...toArr(props.styles).map((relativePath) =>
          pathJoin(url.resourcesPath, relativePath)
        ),
      ]
        .reverse()
        .map((href) =>
          headInsert({
            type: "css",
            href,
            position: "prepend",
          })
        )
    ).then(() => {
      if (isUnmounted) {
        return;
      }

      setExtraCssLoaded();
    });

    toArr(props.scripts).forEach((relativePath) =>
      headInsert({
        type: "javascript",
        src: pathJoin(url.resourcesPath, relativePath),
      })
    );

    if (props.kcHtmlClass !== undefined) {
      const htmlClassList = document.getElementsByTagName("html")[0].classList;

      const tokens = cx(props.kcHtmlClass).split(" ");

      htmlClassList.add(...tokens);

      cleanups.push(() => htmlClassList.remove(...tokens));
    }

    return () => {
      isUnmounted = true;

      cleanups.forEach((f) => f());
    };
  }, [props.kcHtmlClass]);

  if (!isExtraCssLoaded) {
    return null;
  }

  var baseUrl = "";

  if (process.env.REACT_APP_ENV === "debug") {
    baseUrl = "http://127.0.0.1:8088/auth/realms/main";
  } else {
    var host = window.location.hostname;
    var protocol = window.location.protocol;
    var port = window.location.port;
    if (port.length > 0) {
      port = ":" + port;
    }
    if (kcContext.url.loginUrl.includes("/auth/")) {
      baseUrl = protocol + "//" + host + port + "/auth/realms/" + realm.name;
    } else {
      baseUrl = protocol + "//" + host + port + "/realms/" + realm.name;
    }
  }
  // API.init("https://sso.huecity.vn/auth/realms/hues");
  API.init(baseUrl);
  return (
    <SafeAreaProvider>
      <PaperProvider theme={theme}>
        <View
          style={{
            overflow: "hidden",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <Image
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            source={{
              uri: bgImg,
            }}
          />
          <View
            style={{
              alignItems: "center",
              marginTop: 32,
              marginBottom: 32,
              position: "relative",
            }}
          >
            <View
              style={{
                width: props.width ?? "100%",
                minWidth: props.minWidth ? props.minWidth : 310,
                maxWidth: props.maxWidth ? props.maxWidth : 850,
                position: "relative",
                marginBottom: 16,
              }}
            >
              <View style={{ alignSelf: "center", marginBottom: 16 }}>
                {msg("loginTitleHtml", realm.displayNameHtml)}
              </View>
              {displayMessage &&
                message !== undefined &&
                (message.type !== "warning" || !isAppInitiatedAction) && (
                  <span className={cx("alert", `alert-${message.type}`)}>
                    {message.type === "success" && (
                      <span className={cx(props.kcFeedbackSuccessIcon)}></span>
                    )}
                    {message.type === "warning" && (
                      <span className={cx(props.kcFeedbackWarningIcon)}></span>
                    )}
                    {message.type === "error" && (
                      <span className={cx(props.kcFeedbackErrorIcon)}></span>
                    )}
                    {message.type === "info" && (
                      <span className={cx(props.kcFeedbackInfoIcon)}></span>
                    )}
                    <span
                      className="kc-feedback-text"
                      dangerouslySetInnerHTML={{
                        __html: message.summary,
                      }}
                    />
                  </span>
                )}
              <View
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 16,
                  paddingBottom: 22,
                }}
              >
                <TouchableOpacity
                  style={{
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    alignSelf: "flex-start",
                    justifyContent: "center",
                    marginLeft: -4,
                    display: props.onBack ? "flex" : "none",
                  }}
                  onPress={props.onBack}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="chevron-back" size={20} color="#454545" />
                    <Text
                      style={{
                        color: "#454545",
                        fontWeight: "500",
                        fontSize: 14,
                        paddingBottom: 1.4,
                      }}
                    >
                      {props.backLabel ?? "Quay lại"}
                    </Text>
                  </View>
                </TouchableOpacity>
                <View
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    marginBottom: 20,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      justifyContent: "center",
                      fontSize: 20,
                      alignSelf: "center",
                      fontWeight: "500",
                    }}
                    variant="titleLarge"
                  >
                    {headerNode}
                  </Text>
                </View>
                {formNode}
              </View>
            </View>
            {infoNode}
          </View>
        </View>
      </PaperProvider>
    </SafeAreaProvider>
  );
});

export const StateDialog = (props: {
  visible?: boolean | undefined;
  state?: "loading" | "alert";
  message?: string;
  title?: string | null;
  onCancel?: () => void | undefined;
  onConfirm?: () => void | undefined;
  confirmLabel?: string | undefined;
  cancelLabel?: string | undefined;
}) => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        zIndex: 100,
        display: props.visible ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.74)",
      }}
    >
    <View
        style={{
          paddingTop: Dimensions.get("window").height / 3.2,
          width: "100%",
        }}
      >
        <View
          style={{
            backgroundColor: "#fff",
            alignSelf: "center",
            width: "100%",
            maxWidth: 400,
          }}
        >
          <Dialog
            theme={theme}
            style={{
              backgroundColor: "#fff",
              maxWidth: 400,
              width: "100%",
              alignSelf: "center",
            }}
            dismissable={false}
            visible={props.visible ?? false}
          >
            {props.title && (
              <Dialog.Title
                style={{ fontSize: 20, padding: 0, marginBottom: 8 }}
              >
                {props.title}
              </Dialog.Title>
            )}
            <Dialog.Content
              style={{
                flexDirection: "row",
                paddingBottom: props.onCancel ? 8 : 24,
              }}
            >
              {props.state == "loading" && (
                <ActivityIndicator
                  animating={true}
                  color={MD2Colors.red800}
                  style={{ marginRight: 16 }}
                />
              )}
              <Paragraph>
                {props.message ? props.message : "Đang gửi yêu cầu..."}
              </Paragraph>
            </Dialog.Content>
            {props.onCancel != null && (
              <Dialog.Actions
                style={{ marginTop: 0, paddingTop: 0, paddingBottom: 16 }}
              >
                <Button
                  contentStyle={{ padding: 0, margin: 0, height: 32 }}
                  textColor="#6e6e6e"
                  onPress={props.onCancel}
                >
                  {props.cancelLabel ?? "Đóng"}
                </Button>
                {props.onConfirm && (
                  <>
                    <View style={{ width: 12 }} />
                    <Button
                      contentStyle={{
                        padding: 0,
                        margin: 0,
                        height: 32,
                      }}
                      onPress={props.onConfirm}
                    >
                      {props.confirmLabel ?? "Xác nhận"}
                    </Button>
                  </>
                )}
              </Dialog.Actions>
            )}
          </Dialog>
        </View>
      </View> 
    </div>
  );
};

export const isDev = (): boolean => {
  return process.env.REACT_APP_ENV === "debug";
};

export default Template;
