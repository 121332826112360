import axios from "axios";
import { AddressSelectionData } from "components/KcFormInput";
import { url } from "inspector";
import moment from "moment";

export var BASE_URL = "";

export function init(domain: string) {
  BASE_URL = domain;
}
export async function checkQR(qr: string): Promise<boolean> {
  try {
    var res = await axios.get(
      BASE_URL + "/accountresource/loginqr?qr=" + qr,
      {}
    );
    return res.status == 200;
  } catch {
    return false;
  }
}

export async function checkPhoneRegistered(phone: string): Promise<boolean> {
  var data = await checkIdentity(phone, []);
  return data["phone"];
}

export async function requestOTP(phone: string): Promise<void> {
  var res = await axios.get(BASE_URL + "/accountresource/otp", {
    headers: {
      phone: phone,
    },
  });
  var data = res.data as BaseResponse<number>;
  if (data.status.code != 0) {
    throw new BaseDomainError(data.status.message);
  }
}

export async function sendUserProfileForm(
  userProfileData: RegisterData & {
    otp: string;
  }
): Promise<string> {
  var urlPath = "/accountresource";
  var formData = new FormData();
  formData.append("name", userProfileData.name);
  formData.append(
    "birthdate",
    moment(userProfileData.birthDate!).format("DD/MM/YYYY")
  );
  formData.append("otp", userProfileData.otp);
  formData.append("phone", userProfileData.phone);

  if (userProfileData.idNumber) {
    urlPath += "/populationregister";
    formData.append("idNumber", userProfileData.idNumber);
  } else {
    formData.append("gender", userProfileData.gender + "");
    if (
      userProfileData.identityCard &&
      userProfileData.identityCard.length > 1
    ) {
      var cmmd = {
        idNumber: userProfileData.identityCard,
        issuer: null,
        issueDate: null,
        type: 0,
      };
      formData.append("identityPaper", JSON.stringify(cmmd));
    }
    if (userProfileData.citizenCard && userProfileData.citizenCard.length > 1) {
      var cccd = {
        idNumber: userProfileData.citizenCard,
        issuer: null,
        issueDate: null,
        type: 1,
      };
      formData.append("identityPaper", JSON.stringify(cccd));
    }
    formData.append(
      "address",
      JSON.stringify({
        provinceId: userProfileData.residentAddress?.provinceId,
        districtId: userProfileData.residentAddress?.districtId,
        wardId: userProfileData.residentAddress?.wardId,
        groupId: null,
        address: userProfileData.residentAddress?.address,
        type: 1,
      })
    );
  }
  if (userProfileData.avatar) {
    formData.append("avatar", userProfileData.avatar);
  }
  if (userProfileData.password) {
    formData.append("password", userProfileData.password);
  }
  var res = await axios({
    url: BASE_URL + urlPath,
    method: "POST",
    data: formData,
  });
  var data = res.data as BaseResponse<any>;
  if (data.status.code != 0) {
    throw new BaseDomainError(data.status.message);
  } else {
    return data.data!;
  }
}

export async function checkAccountData(
  personIdentity: string,
  name: string,
  birthDate: Date | null,
  phone: string
): Promise<boolean> {
  try {
    var res = await axios({
      url: BASE_URL + "/accountresource/check",
      method: "POST",
      data: {
        phone: phone,
        name: name,
        idNumber: personIdentity,
        birthdate:
          birthDate != null ? moment(birthDate).format("DD/MM/YYYY") : "",
      },
    });
    var data = res.data as BaseResponse<any>;
    if (data.status.code != 0) {
      if (data.status.code != 2) {
        throw new BaseDomainError(data.status.message);
      } else {
        return false;
      }
    } else {
      return true;
    }
  } catch (e) {
    throw e;
  }
}

export async function updateCitizenData(
  username: string | null,
  citizenId: string | null,
  name: string | null,
  birthDate: Date | null,
  phone: string,
  otp: string,
  authSession?: {
    clientId: string;
    tabId: string;
  }
): Promise<any> {
  var url = BASE_URL + "/accountresource/update";
  if (authSession) {
    url =
      url +
      "?tab_id=" +
      authSession.tabId +
      "&client_id=" +
      authSession.clientId;
  }
  try {
    var res = await axios({
      url: url,
      method: "POST",
      data: {
        username: username,
        phone: phone,
        name: name,
        idNumber: citizenId,
        otp: otp,
        birthdate:
          birthDate != null ? moment(birthDate).format("DD/MM/YYYY") : null,
      },
    });
    var data = res.data as BaseResponse<any>;
    if (data.status.code != 0) {
      throw new BaseDomainError(data.status.message);
    } else {
      return true;
    }
  } catch (e) {
    throw e;
  }
}

export async function checkCitizenUpdateData(
  username: string | null,
  citizenId: string | null,
  name: string | null,
  birthDate: Date | null,
  phone: string | null,
  authSession?: {
    clientId: string;
    tabId: string;
  }
): Promise<any> {
  var url = BASE_URL + "/accountresource/checkCitizenUpdateData";
  if (authSession) {
    url =
      url +
      "?tab_id=" +
      authSession.tabId +
      "&client_id=" +
      authSession.clientId;
  }
  try {
    var res = await axios({
      url: url,
      method: "POST",
      data: {
        username: username,
        phone: phone,
        name: name,
        citizenId: citizenId,
        birthdate:
          birthDate != null ? moment(birthDate).format("DD/MM/YYYY") : null,
      },
      withCredentials: true,
    });
    var data = res.data as BaseResponse<any>;
    if (data.status.code != 0) {
      throw new BaseDomainError(data.status.message);
    } else {
      return true;
    }
  } catch (e) {
    throw e;
  }
}

export async function checkResgister(
  personIdentity: string,
  name: string,
  birthDate: Date | null,
  phone: string
): Promise<boolean> {
  try {
    var res = await axios({
      url: BASE_URL + "/accountresource/checkregister",
      method: "POST",
      data: {
        phone: phone,
        name: name,
        idNumber: personIdentity,
        birthdate:
          birthDate != null ? moment(birthDate).format("DD/MM/YYYY") : "",
      },
    });
    var data = res.data as BaseResponse<any>;
    if (data.status.code != 0) {
      if (data.status.code != 2) {
        throw new BaseDomainError(data.status.message);
      } else {
        return false;
      }
    } else {
      return true;
    }
  } catch (e) {
    throw e;
  }
}

export async function checkIdentity(
  phone: string,
  identityPagers: string[]
): Promise<Record<string, boolean>> {
  try {
    var res = await axios({
      url: BASE_URL + "/accountresource/checkidentity",
      method: "POST",
      data: {
        phone: phone,
        identityPapers: identityPagers,
      },
    });
    var data = res.data as BaseResponse<any>;
    if (data.status.code == 0) {
      var checkResult: Record<string, boolean> = {};
      checkResult["phone"] = data.data["phone"];
      for (var item of identityPagers) {
        checkResult[item] = data.data[item];
      }
      return checkResult;
    } else {
      throw new BaseDomainError(data.status.message);
    }
  } catch (e) {
    throw e;
  }
}

export async function getProvinceList(): Promise<DataWithId[]> {
  var res = await axios.get(
    "https://sso.huecity.vn/auth/realms/hues/category/province"
  );
  // await new Promise(resolve => setTimeout(resolve, 2000));
  var data = res.data;
  if (data["Success"]) {
    var options: DataWithId[] = [];
    for (var item of data["Data"]) {
      options.push({
        id: item["id"],
        title: item["ten"],
      });
    }
    return options;
  } else {
    throw new BaseDomainError(data["Message"]);
  }
}

async function sleep(time: number) {
  return await new Promise((resolve) => setTimeout(resolve, time));
}

export class BaseDomainError extends Error {
  constructor(m?: string) {
    super(m);
    this.name = "BaseDomainError";
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export type BaseResponse<T> = {
  status: {
    code: number;
    message: string;
  };
  data?: T;
};

export type DataWithId = {
  title: string;
  id: string;
};

export type RegisterData = {
  name: string;
  birthDate: Date | null;
  phone: string;
  gender?: number | null;
  idNumber?: string | null;
  identityCard?: string | null;
  citizenCard?: string | null;
  residentAddress?: AddressSelectionData | null;
  password?: string | null;
  avatar?: File | null;
};
