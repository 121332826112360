/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
// This is a copy paste from https://github.com/InseeFrLab/keycloakify/blob/main/src/lib/components/Register.tsx
// It is now up to us to implement a special behavior to leverage the non standard authorizedMailDomains
// provided by the plugin: https://github.com/micedre/keycloak-mail-whitelisting installed on our keycloak server.
// Note that it is no longer recommended to use register.ftl, it's best to use register-user-profile.ftl
// See: https://docs.keycloakify.dev/realtime-input-validation

import React, { memo } from "react";
import type { KcProps } from "keycloakify";
import { kcContext, KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import { Button, Portal, Text } from "react-native-paper";
import Template, { isDev, StateDialog } from "components/Template";
import { View } from "react-native";
import {
  KcDateInputPicker,
  KcTextInput,
} from "components/KcFormInput";
import {
  BaseDomainError,
  checkCitizenUpdateData,
  updateCitizenData,
} from "api";
import moment from "moment";
import { OTPInput } from "./PhoneOTPLogin";
import { InfoNode } from "./Login";

type KcContext_Data = Extract<
  KcContext,
  { pageId: "hues-login-check-citizen-info.ftl" }
>;

const Register = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_Data; i18n: I18n } & KcProps) => {
    const [step, setStep] = React.useState(1);
    // kcContext.inAppReview;
    // const [userToken, setUserToken] = React.useState<string | null>(null);
    const [updatedUserInfo, setUpdatedUserInfo] = React.useState<UserInfoType>(
      kcContext.userinfo
    );

    const [state, setState] = React.useState<{
      loading: boolean;
      message?: string | undefined;
    } | null>(null);
    const formRef = React.useRef<HTMLFormElement>(null);
    return (
      <Template
        {...{ kcContext, i18n, ...props }}
        doFetchDefaultThemeResources={true}
        headerNode={"Cập nhật thông tin"}
        maxWidth={520}
        onBack={() => {
          if (step == 2) {
            setStep(1);
          } else {
            window.location.href = kcContext.url.loginRestartFlowUrl;
          }
        }}
        backLabel={step > 1 ? "Quay lại" : "Đăng nhập"}
        formNode={
          <>
            <View
              style={
                step != 1
                  ? { height: 0, overflow: "hidden", display: "none" }
                  : {}
              }
            >
              <IdentityForm
                username={kcContext.userinfo.username}
                disabled={step != 1}
                citizenMatched={kcContext.citizenMatched}
                phoneVerified={kcContext.phoneVerified}
                data={{
                  birthdate: updatedUserInfo.birthdate,
                  name: updatedUserInfo.name,
                  citizenId: updatedUserInfo.citizenId,
                  phone: updatedUserInfo.phone,
                  identityCardId: null,
                }}
                authSession={kcContext.authSession}
                onSubmit={(data) => {
                  setUpdatedUserInfo(data);
                  setStep(step + 1);
                }}
              />
            </View>
            {step == 2 && (
              <OTPInput
                disabled={state != null}
                submitLabel={"Gửi"}
                phone={updatedUserInfo.phone ?? ""}
                onSubmit={async (otp) => {
                  try {
                    setState({
                      loading: true,
                    });
                    if (kcContext.citizenMatched) {
                      await updateCitizenData(
                        kcContext.userinfo.username,
                        null,
                        null,
                        null,
                        updatedUserInfo.phone!,
                        otp,
                        kcContext.authSession
                      );
                    } else {
                      await updateCitizenData(
                        kcContext.userinfo.username,
                        updatedUserInfo.citizenId,
                        updatedUserInfo.name,
                        moment(
                          updatedUserInfo.birthdate!,
                          "DD/MM/yyyy"
                        ).toDate(),
                        updatedUserInfo.phone!,
                        otp,
                        kcContext.authSession
                      );
                    }
                    setState(null);
                    window.location.reload();
                  } catch (e) {
                    var error = "Có lỗi xảy ra. Vui lòng thử lại";
                    if (e instanceof BaseDomainError) {
                      error = e.message;
                    }
                    setState({
                      loading: false,
                      message: error,
                    });
                  }
                }}
              />
            )}

            <Portal>
              {state != null && (
                <StateDialog
                  onCancel={
                    state?.loading == true
                      ? undefined
                      : () => {
                          setState(null);
                        }
                  }
                  state={state?.loading == true ? "loading" : "alert"}
                  visible={true}
                  message={state?.message ?? ""}
                />
              )}
            </Portal>
          </>
        }
        infoNode={<InfoNode />}
      />
    );
  }
);

const IdentityForm = (props: {
  disabled: boolean;
  onSubmit: (data: UserInfoType) => void;
  username: string | null;
  phoneVerified: boolean;
  citizenMatched: boolean;
  authSession?: {
    clientId: string;
    tabId: string;
  };
  data: UserInfoType;
}) => {
  const [name, setName] = React.useState(props.data.name ?? "");
  const [phone, setPhone] = React.useState(props.data.phone ?? "");
  const [birthDate, setBirthDate] = React.useState<Date | null>(
    props.data?.birthdate
      ? moment(props.data.birthdate!, "DD/MM/YYYY").toDate()
      : null
  );
  const [citizenId, setCitizenId] = React.useState(props.data.citizenId ?? "");
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);
  // const [citizenMatched, setCitizenMatched] = React.useState(
  //   props.citizenMatched
  // );

  const validateForm = (): string[] => {
    var messages: string[] = [];
    if (name.length == 0) {
      messages.push("- Chưa nhập họ tên");
    }
    if (phone.length == 0) {
      messages.push("- Chưa nhập số điện thoại");
    } else if (phone.length != 10 || !phone.startsWith("0")) {
      messages.push("- Số điện thoại không hợp lệ");
    }
    if (birthDate == null) {
      messages.push("- Chưa nhập ngày sinh");
    }
    if (citizenId.length == 0) {
      messages.push("- Chưa nhập số căn cước công dân");
    } else if (citizenId.length != 12) {
      messages.push("- Số căn cước công dân không hợp lệ");
    }
    return messages;
  };
  return (
    <>
      <Text style={{ marginBottom: 16, textAlign: "center" }}>
        {!props.citizenMatched
          ? "Vui lòng nhập các thông tin cá nhân của bạn trùng khớp với thông tin  trên thẻ Căn cước công dân."
          : "Vui lòng cập nhật lại số điện thoại của tài khoản."}
      </Text>
      {!props.citizenMatched && (
        <>
          <KcTextInput
            require={true}
            placeholder="Nhập đầy đủ họ tên khai sinh"
            first={true}
            tabIndex={1}
            disabled={props.disabled}
            defaultValue={props.data?.name ?? ""}
            onChangeText={(value) => {
              setName(value.trim());
            }}
            title="Họ và tên"
          />
          <KcDateInputPicker
            require={true}
            placeholder="ngày/tháng/năm"
            zIndex={12}
            tabIndex={2}
            defaultValue={props.data?.birthdate ?? undefined}
            onChange={(value) => {
              setBirthDate(value);
            }}
            title="Ngày sinh"
          />
          <KcTextInput
            require={true}
            disabled={props.disabled}
            placeholder="Nhập 12 số CCCD"
            maxLength={12}
            tabIndex={3}
            title="Căn cước công dân"
            defaultValue={props.data?.citizenId ?? ""}
            keyboardType="numeric"
            onChangeText={(value) => {
              setCitizenId(value.trim());
            }}
          />
        </>
      )}
      <KcTextInput
        require={true}
        tabIndex={4}
        first={props.citizenMatched}
        placeholder="Nhập số di động"
        helper="Mã xác thực OTP sẽ gửi về số này"
        title="Số điện thoại"
        defaultValue={props?.data?.phone ?? ""}
        onChangeText={(value) => {
          setPhone(value.trim());
        }}
        keyboardType="numeric"
      />
      <Button
        disabled={props.disabled}
        onPress={async () => {
          var errors = validateForm();
          if (errors.length != 0) {
            setState({
              loading: false,
              message: errors.join("\n"),
            });
          } else {
            try {
              setState({
                loading: true,
              });
              await checkCitizenUpdateData(
                props.username,
                citizenId,
                name,
                birthDate,
                phone,
                props.authSession
              );
              setState(null);
              props.onSubmit({
                birthdate: moment(birthDate!).format("DD/MM/YYYY"),
                citizenId: citizenId,
                identityCardId: null,
                name: name,
                phone: phone,
              });
            } catch (e) {
              var error = "Có lỗi xảy ra. Vui lòng thử lại";
              if (e instanceof BaseDomainError) {
                error = e.message;
              }
              setState({
                loading: false,
                message: error,
              });
            }
          }
        }}
        style={{ marginTop: 32 }}
        mode="contained"
      >
        {"Cập nhật".toUpperCase()}
      </Button>

      {state != null && (
        <Portal>
          <StateDialog
            onCancel={
              state?.loading == true
                ? undefined
                : () => {
                    setState(null);
                  }
            }
            state={state?.loading == true ? "loading" : "alert"}
            visible={true}
            message={state?.message ?? ""}
          />
        </Portal>
      )}
    </>
  );
};

type UserInfoType = {
  name: string | null;
  phone: string | null;
  birthdate: string | null;
  citizenId: string | null;
  identityCardId: string | null;
};

export default Register;
