import React, { memo, useState } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import Template, { StateDialog } from "components/Template";
import { KcTextInput } from "components/KcFormInput";
import { Button, Portal, Text } from "react-native-paper";
import { BaseDomainError, checkIdentity, requestOTP } from "api";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { TouchableOpacity, View } from "react-native";
import { Image } from "react-native";
import styled from "styled-components";
import { isMobileApp } from "./KcApp";
type KcContext_AuthSelectionLogin = Extract<
  KcContext,
  { pageId: "hues-login-auth-selection.ftl" }
>;

const AuthSelectionLogin = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_AuthSelectionLogin; i18n: I18n } & KcProps) => {
    const [loading, setLoading] = useState(false);
    React.useEffect(() => {
      var a: any = window;
      if (a.ReactNativeWebView) {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const redirectUrl = params.get("redirect_uri");
        if (
          (redirectUrl == null || !redirectUrl?.startsWith("hues")) &&
          !windowUrl.includes("UPDATE_PASSWORD")
        ) {
          a.ReactNativeWebView.postMessage("navigateLogin");
        }
      }
    }, []);
    const [helperCodeShowing, setHelperCodeShowing] = React.useState(
      kcContext.helperCodeError
    );

    return (
      <View>
        <Template
          {...{ kcContext, i18n, ...props }}
          doFetchDefaultThemeResources={true}
          maxWidth={520}
          headerNode={"Đăng nhập"}
          backLabel={helperCodeShowing ? "Quay lại" : undefined}
          onBack={
            helperCodeShowing
              ? () => {
                  setHelperCodeShowing(false);
                }
              : undefined
          }
          formNode={
            <>
              {!helperCodeShowing && (
                <SelectionForm
                  onLoading={() => {
                    setLoading(true);
                  }}
                  items={kcContext.items}
                  loginActionUrl={kcContext.url.loginAction}
                />
              )}
              {helperCodeShowing && (
                <HelperCodeForm
                  disabled={loading}
                  loginActionUrl={kcContext.url.loginAction}
                  onLoading={() => {
                    setLoading(true);
                  }}
                />
              )}
              <TouchableOpacity
                onPress={() => {
                  setHelperCodeShowing(true);
                }}
                style={{
                  display:
                    !helperCodeShowing && kcContext.helperCodeEnabled
                      ? "flex"
                      : "none",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <Text
                  variant="titleSmall"
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    marginTop: 16,
                    color: "#5c5c5c",
                  }}
                >
                  Trong trường hợp đăng nhập VNeID bị lỗi, vui lòng bấm vào đây
                  để được hỗ trợ.
                </Text>
              </TouchableOpacity>
            </>
          }
        />
        <StateDialog state="loading" visible={loading} />
      </View>
    );
  }
);

const HelperCodeForm = (props: {
  onLoading: () => void;
  disabled: boolean;
  loginActionUrl: string;
}) => {
  const [code, setCode] = React.useState("");
  return (
    <View>
      <form
        id="form"
        method="POST"
        action={props.loginActionUrl}
        style={{ display: "none" }}
      >
        <input
          tabIndex={-1}
          hidden
          id="helperCode"
          name="helperCode"
          value={""}
        />
      </form>
      <Text variant="bodyMedium" style={{ textAlign: "center" }}>
        Vui lòng liên hệ tổng đài để nhận mã số hỗ trợ. Sau khi
        nhập số hỗ trợ bạn có thể tiến hành đăng nhập bằng mã OTP gửi đến số
        điện thoại.
      </Text>
      <KcTextInput
        // disabled={props.disabled || props.lockValue}
        maxLength={8}
        helper="Nhập số do tổng đài cung cấp"
        // defaultValue={props.phone}
        keyboardType={"numeric"}
        onChangeText={(value) => {
          setCode(value);
        }}
        title="Mã số hỗ trợ"
      />
      <Button
        disabled={props.disabled || code.trim().length == 0}
        onPress={() => {
          props.onLoading();
          setTimeout(() => {
            //@ts-ignore
            document.getElementById("helperCode").value = code.trim();
            //@ts-ignore
            document.getElementById("form").submit();
          }, 15);
        }}
        style={{ marginTop: 20 }}
        mode="contained"
      >
        {"Gửi".toUpperCase()}
      </Button>
    </View>
  );
};

const SelectionForm = (props: {
  loginActionUrl: string;
  onLoading: () => void;
  items: {
    id: string;
    title: string;
    name: string;
    appDeepLink?: string;
    icon: string;
  }[];
}) => {
  return (
    <>
      <Text
        style={{
          alignSelf: "center",
          marginBottom: 24,
          textAlign: "center",
        }}
      >
        Vui lòng chọn loại tài khoản bạn muốn sử dụng để đăng nhập
      </Text>
      <View>
        {(() => {
          return props.items.map((e) => {
            return (
              <SelectionButton
                onClick={() => {
                  if (
                    //@ts-ignore
                    window.ReactNativeWebView != null &&
                    e.appDeepLink != null
                  ) {
                    //@ts-ignore
                    window.ReactNativeWebView.postMessage(
                      "deepLink:" +
                        JSON.stringify({
                          link: e.appDeepLink,
                          name: e.name,
                        })
                    );
                  } else {
                    props.onLoading();
                    setTimeout(() => {
                      //@ts-ignore
                      document.getElementById("provider").value = e.id;
                      //@ts-ignore
                      document.getElementById("form").submit();
                    }, 15);
                  }
                }}
                key={"loginButton_" + e.id}
                style={{}}
              >
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: "500" }}> {e.title}</div>
                  <div style={{ fontWeight: "bold", marginTop: -2 }}>
                    {e.name}
                  </div>
                </div>
                <img
                  src={e.icon}
                  alt={e.name}
                  style={{
                    width: 52,
                    height: 52,
                    border: "0.1px solid #fff",
                    borderRadius: "12%",
                  }}
                />
              </SelectionButton>
            );
          });
        })()}
        <form
          id="form"
          method="POST"
          action={props.loginActionUrl}
          style={{ display: "none" }}
        >
          <input id="provider" name="kc_idp_hint" />
        </form>
      </View>
    </>
  );
};

const SelectionButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #2477f2;
  text-align: center;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #2477f2;
  :not(:last-child) {
    margin-bottom: 6px;
  }
  &:hover {
    border-color: #82bdfa;
    color: #fff;
    font-weight: 600;
    background-color: #1e88e5;
  }
`;

export default AuthSelectionLogin;
