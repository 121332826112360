import { getKcContext } from "keycloakify/lib/getKcContext";

export const { kcContext } = getKcContext<
  // NOTE: A 'keycloakify' field must be added
  // in the package.json to generate theses pages
  // https://docs.keycloakify.dev/build-options#keycloakify.extrapages
  | { pageId: "my-extra-page-1.ftl" }
  | { pageId: "my-extra-page-2.ftl"; someCustomValue: string }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | { pageId: "register.ftl"; authorizedMailDomains: string[] }
>({
  // Uncomment to test the login page for development.
  // Try with another page like "register-user-profile.ftl"
  // DON'T forget to re-comment before publishing to production.
  // You must run 'yarn keycloak' at least once before testing locally.
  mockPageId: process.env.REACT_APP_ENV === "debug" ? "register.ftl" : undefined,
  mockData: [
    {
      pageId: "hues-login-auth-selection.ftl",
      helperCodeEnabled: true,
      items: [
        {
          id: "vneid",
          title: "Tài khoản định danh điện tử",
          name: "VNeID",
          appDeepLink: "vneid://app",
          icon: "https://dichvu.huecity.vn/uploads/vneid_1224222b2b.jpg",
        },
        // {
        //   id: "hues",
        //   title: "Tài khoản công dân",
        //   name: "Hue-S",
        //   icon: "https://dichvu.huecity.vn/uploads/hues_d160859760.jpg",
        // },
      ],
      realm: {
        displayNameHtml:
          "<div style='color:rgb(1, 143, 229);margin-top:16px;font-size:24px;text-transform: uppercase;font-weight: bold;'>Hue-S SSO</div>",
      },
    },
    {
      pageId: "hues-login-check-citizen-info.ftl",
      inAppReview: false,
      phoneVerified: false,
      citizenMatched: false,
      userinfo: {
        username: "hup468863022113",
        birthdate: "10/12/1993",
        citizenId: "046093003258",
        identityCardId: "191789120",
        name: "Phùng Văn Hoà",
        phone: "0766248686",
      },
    },
    {
      pageId: "hues-login-idp-review-user.ftl",
      idpName: "Cổng dịch vụ công Quốc gia",
      username: "username1",
      users: [
        {
          username: "username1",
          name: "van hoa1",
          birthdate: "10/12/1993",
          gender: "Nam",
          updatedAt: "10/12/1993",
          createdAt: "10/12/1993",
          phone: "0766248686",
          authType: null,
          avatar:
            "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
          identityCardId: "191789120",
          citizenId: "191789120",
        },
        {
          username: "username2",
          name: "van hoa22222",
          birthdate: "10/12/1993",
          gender: "Nam",
          updatedAt: "10/12/1993",
          createdAt: "10/12/1993",
          phone: "0766248282",
          authType: "otp",
          avatar:
            "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
          identityCardId: "191789120",
          citizenId: "191789120",
        },
      ],
    },
    {
      pageId: "hues-phone-login-select-user.ftl",
      phone: "0766248686",
      users: [
        {
          username: "username1",
          name: "van hoa1",
          birthDate: "10/12/1993",
          gender: 1,
          lastUpdated: "10/12/1993",
          avatar:
            "https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg",
          identityCardId: "191789120",
          citizenId: "123",
          // maskedPaperId: "********123",
        },
        {
          username: "username2",
          name: "van hoa 2",
          birthDate: "09/09/1993",
          lastUpdated: "28/12/1993",
          identityCardId: "191789120",
          citizenId: "123",
          gender: null,
          // maskedPaperId: "*******122",
        },
      ],
    },
    {
      pageId: "login-update-password.ftl",
      auth: {
        attemptedUsername: undefined,
      },

      isAppInitiatedAction: true,
      realm: {
        displayNameHtml:
          "<div style='color:rgb(1, 143, 229);margin-top:16px;font-size:24px;text-transform: uppercase;font-weight: bold;'>Hue-S SSO</div>",
      },
    },
    {
      pageId: "hues-login-phone-otp.ftl",
      auth: {
        attemptedUsername: undefined,
      },
      isAppInitiatedAction: true,
      realm: {
        displayNameHtml:
          "<div style='color:rgb(1, 143, 229);margin-top:16px;font-size:24px;text-transform: uppercase;font-weight: bold;'>Hue-S SSO</div>",
      },
      // phone: "0766248616",
    },
    {
      pageId: "login.ftl",
      realm: {
        displayNameHtml:
          "<div style='color:rgb(1, 143, 229);margin-top:16px;font-size:24px;text-transform: uppercase;font-weight: bold;'>Hue-S SSO</div>",
        rememberMe: true,
        registrationAllowed: true,
      },
      // social: {
      //   displayInfo: true,
      //   providers: [
      //     {
      //       alias: "aaa",
      //       displayName: "Cổng dịch cụ công quốc gia",
      //       loginUrl: "loginUrl",
      //       providerId: "dvc",
      //     },
      //   ],
      // },
      login: {
        username: "asas11",
      },
      auth: {
        attemptedUsername: "0766248686",
      },
      usernameHidden: false,
      noPassword: false,
      isAppInitiatedAction: false,
      loginQR: "undefined",
      showResetLogin: false,
      forceAuthPhone: false,
      // message: undefined,
      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "vi",
      },
    },
    {
      pageId: "my-extra-page-2.ftl",
      someCustomValue: "foo bar baz",
    },

    {
      pageId: "register.ftl",
      inAppReview: false,
      realm: {
        displayNameHtml:
          "<div style='color:rgb(1, 143, 229);margin-top:16px;font-size:24px;text-transform: uppercase;font-weight: bold;'>Hue-S SSO</div>",
      },
      // message: {
      // 	type: "error",
      // 	summary:'<span class="kc-feedback-text">Invalid username or password.<br>userNotFound</span>'
      // },
      message: undefined,
      authorizedMailDomains: [
        "example.com",
        "another-example.com",
        "*.yet-another-example.com",
        "*.example.com",
        "hello-world.com",
      ],
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: "register-user-profile.ftl",
      locale: {
        currentLanguageTag: "fr",
      },
      profile: {
        attributes: [
          {
            validators: {
              pattern: {
                pattern: "^[a-zA-Z0-9]+$",
                "ignore.empty.value": true,
                // eslint-disable-next-line no-template-curly-in-string
                "error-message": "${alphanumericalCharsOnly}",
              },
            },
            //NOTE: To override the default mock value
            value: undefined,
            name: "username",
          },
          {
            validators: {
              options: {
                options: [
                  "male",
                  "female",
                  "non-binary",
                  "transgender",
                  "intersex",
                  "non_communicated",
                ],
              },
            },
            // eslint-disable-next-line no-template-curly-in-string
            displayName: "${gender}",
            annotations: {},
            required: true,
            groupAnnotations: {},
            readOnly: false,
            name: "gender",
          },
        ],
      },
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext>;
