import React, { useState, memo } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { KcContextBase } from "keycloakify/lib/getKcContext/KcContextBase";
import { useCssAndCx } from "keycloakify/lib/tools/useCssAndCx";
import { useConstCallback } from "powerhooks/useConstCallback";
import type { FormEventHandler } from "react";
import type { I18n } from "./i18n";
import { TouchableOpacity, View } from "react-native";
import {
  Button,
  Checkbox,
  Text,
  Provider as PaperProvider,
  MD3LightTheme as DefaultTheme,
  Portal,
} from "react-native-paper";
import { KcTextInput } from "components/KcFormInput";
import Template, { StateDialog } from "components/Template";
import QRCode from "react-qr-code";
import axios from "axios";
import { BaseDomainError, checkPhoneRegistered, checkQR } from "api";
import styled from "styled-components";
import PhoneOtpLoginForm from "./PhoneOtpLoginForm";
type KcContext_Login = Extract<KcContext, { pageId: "login.ftl" }>;
const Login = memo(
  ({
    kcContext,
    i18n,
    doFetchDefaultThemeResources = true,
    ...props
  }: {
    kcContext: KcContext_Login;
    i18n: I18n;
    doFetchDefaultThemeResources?: boolean;
  } & KcProps) => {
    const { social, realm, url, login, auth, loginQR } = kcContext;

    const inputRef = React.useRef<HTMLFormElement>(null);

    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [cancel, setCancel] = React.useState(false);
    const onSubmit = useConstCallback<FormEventHandler<HTMLFormElement>>(
      (e) => {
        e.preventDefault();
        const formElement = e.target as HTMLFormElement;
        //NOTE: Even if we login with email Keycloak expect username and password in
        //the POST request.
        formElement
          .querySelector("input[name='email']")
          ?.setAttribute("name", "username");
        formElement.submit();
      }
    );
    const submitForm = () => {
      setLoading(true);
      setTimeout(() => {
        inputRef.current?.submit();
      }, 200);
    };
    var errorMsg: string | null = null;
    try {
      errorMsg = kcContext.messagesPerField.get("username");
    } catch (e) {}
    if (errorMsg == null) {
      try {
        errorMsg = kcContext.messagesPerField.get("password");
      } catch (e) {}
    }
    if (kcContext.message?.summary && errorMsg != null) {
      kcContext.message!.summary = errorMsg;
    }
    React.useEffect(() => {
      var a: any = window;
      if (a.ReactNativeWebView) {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const redirectUrl = params.get("redirect_uri");
        if (
          (redirectUrl == null || !redirectUrl?.startsWith("hues")) &&
          !windowUrl.includes("UPDATE_PASSWORD")
        ) {
          a.ReactNativeWebView.postMessage("navigateLogin");
        }
      }
    }, []);

    // kcContext.url.loginRestartFlowUrl
    console.log("isAppInitiatedAction", kcContext.isAppInitiatedAction);
    return (
      <View style={{ flex: 1 }}>
        <Template
          {...{ kcContext, i18n, doFetchDefaultThemeResources, ...props }}
          // displayInfo={social.displayInfo}
          // displayInfo={social.displayInfo}
          // displayWide={realm.password && social.providers !== undefined}
          maxWidth={
            // forgotPassword || kcContext.usernameHidden ? 480 : undefined
            forgotPassword || kcContext.forceAuthPhone ? 515 : undefined
          }
          onBack={
            !forgotPassword
              ? kcContext.isAppInitiatedAction
                ? () => {
                    setCancel(true);
                    setLoading(true);
                    setTimeout(() => {
                      inputRef.current?.submit();
                    }, 60);
                  }
                : kcContext.showResetLogin
                ? () => {
                    setCancel(true);
                    setLoading(true);
                    window.location.href = kcContext.url.loginRestartFlowUrl;
                  }
                : undefined
              : () => {
                  setForgotPassword(false);
                }
          }
          headerNode={forgotPassword ? "Quên mật khẩu" : "Đăng nhập"}
          formNode={
            <View style={{ flex: 1 }}>
              <form
                id="kc-form-login"
                ref={inputRef}
                onSubmit={onSubmit}
                action={url.loginAction}
                method="post"
              >
                {cancel && (
                  <input
                    type="checkbox"
                    id="cancel-aia"
                    name="cancel-aia"
                    checked
                    value={"on"}
                    style={{ display: "none" }}
                    tabIndex={-1}
                  />
                )}
                <input
                  type="hidden"
                  id="id-hidden-input"
                  name="credentialId"
                  tabIndex={-1}
                  {...(auth?.selectedCredential !== undefined
                    ? {
                        value: auth.selectedCredential,
                      }
                    : {})}
                />
                {!forgotPassword && !kcContext.forceAuthPhone && (
                  <UsernamePasswordForm
                    usernameHidden={kcContext.usernameHidden}
                    noPassword={kcContext.noPassword}
                    realm={realm.name}
                    loginQR={loginQR}
                    username={login.username}
                    rememberMe={login.rememberMe}
                    rememberMeEnabled={realm.rememberMe}
                    disabled={loading}
                    social={social}
                    onForgetPassword={() => {
                      setForgotPassword(true);
                      if (kcContext.message?.summary) {
                        kcContext.message = undefined;
                      }
                      if (kcContext.isAppInitiatedAction) {
                        setTimeout(() => {
                          submitForm();
                        }, 60);
                      }
                    }}
                    onSubmit={submitForm}
                  />
                )}
                {(forgotPassword || kcContext.forceAuthPhone) && (
                  <PhoneOtpLoginForm
                    setLoading={(loading) => {
                      setLoading(loading);
                    }}
                    // phone={kcContext.auth.attemptedUsername}
                    // lockValue={kcContext.auth.attemptedUsername != null}
                    disabled={loading}
                    onSubmit={submitForm}
                    onBack={() => {
                      if (kcContext.message?.summary) {
                        kcContext.message = undefined;
                      }
                      setForgotPassword(false);
                    }}
                  />
                )}
                {/* {kcContext.isAppInitiatedAction && (
                  <Button
                    textColor="#757575"
                    disabled={loading}
                    style={{ marginTop: 16 }}
                    onPress={() => {
                      setCancel(true);
                      setLoading(true);
                      setTimeout(() => {
                        inputRef.current?.submit();
                      }, 60);
                    }}
                  >
                    {"Quay lại"}
                  </Button>
                )} */}
              </form>
            </View>
          }
          infoNode={
            <InfoNode
              registerUrl={
                kcContext.usernameHidden ||
                kcContext.login.username != null ||
                !kcContext.realm.registrationAllowed
                  ? undefined
                  : url.registrationUrl
              }
            />
          }
        />
        <StateDialog state="loading" visible={loading} />
      </View>
    );
  }
);

const SocialIdpDivider = styled.h5`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #7a7a7a;
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto 10px;
  }
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto 10px;
  }
`;

export const InfoNode = (props: { registerUrl?: string }) => {
  return (
    <View>
      {props.registerUrl != null && props.registerUrl.length > 1 && (
        <Text
          style={{ marginTop: 0, alignSelf: "center", color: "#fff" }}
          variant="titleSmall"
        >
          Bạn chưa có tài khoản?{" "}
          <Button
            onPress={() => {
              window.location.href = props.registerUrl!;
            }}
            contentStyle={{ marginLeft: -4 }}
          >
            Đăng ký
          </Button>
        </Text>
      )}
    </View>
  );
};

const UsernamePasswordForm = (props: {
  disabled: boolean;
  usernameHidden: boolean;
  noPassword?: boolean;
  username?: string | undefined;
  realm: string;
  loginQR?: string | undefined;
  rememberMe?: boolean | undefined;
  rememberMeEnabled?: boolean | undefined;
  social?: {
    displayInfo: boolean;
    providers?: {
      loginUrl: string;
      alias: string;
      providerId: string;
      displayName: string;
    }[];
  };
  onSubmit: () => void;
  onForgetPassword: () => void;
}) => {
  const [showQR, setShowQR] = useState(window.innerWidth >= 800);
  const updateWidth = () => {
    setShowQR(window.innerWidth >= 800);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });
  const [username, setUsername] = React.useState(props.username ?? "");
  const [password, setPassword] = React.useState("");
  const [loginByQR, setLoginByQR] = React.useState(false);
  let url = window.location.href;
  const params: any = {};
  if (url.includes("?")) {
    for (let data of (url + "").split("?")[1].split("&")) {
      const keyValue = data.split("=");
      params[keyValue[0]] = keyValue[1];
    }
  }
  var a: any = window;
  var inappWebview = a.ReactNativeWebView != null;
  const [rememberMe, setRememberMe] = React.useState(
    (props.rememberMe ?? false) || inappWebview
  );

  return (
    <View
      style={{
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
        }}
      >
        <View style={{ display: "none" }}>
          {/* {!props.usernameHidden && ( */}
          <input name="username" tabIndex={-1} value={username} />
          {/* )} */}
          {/* {!props.noPassword && ( */}
          <input
            name="password"
            tabIndex={-1}
            value={!props.noPassword ? password : ""}
          />
          {/* )} */}

          {loginByQR && <input name="loginQR" tabIndex={-1} value={"true"} />}

          {!props.usernameHidden && (
            <input
              name="rememberMe"
              tabIndex={-1}
              type="checkbox"
              checked={rememberMe}
            />
          )}
        </View>
        {!props.usernameHidden && (
          <KcTextInput
            tabIndex={1}
            onChangeText={(value) => {
              setUsername(value);
            }}
            onKeyPress={(e) => {
              if ("Enter" == e?.nativeEvent.key) {
                props.onSubmit();
              }
            }}
            defaultValue={username}
            disabled={props.disabled}
            first={true}
            placeholder={"Số điện thoại, CMND hoặc CCCD"}
            title={"Tài khoản"}
          />
        )}
        {!props.noPassword && (
          <>
            <KcTextInput
              first={props.usernameHidden}
              tabIndex={2}
              onChangeText={(value) => {
                setPassword(value);
              }}
              onKeyPress={(e) => {
                if ("Enter" == e?.nativeEvent.key) {
                  props.onSubmit();
                }
              }}
              disabled={props.disabled}
              title={"Mật khẩu"}
              secureTextEntry={true}
            />

            {props.rememberMeEnabled && !props.usernameHidden && (
              <TouchableOpacity
                style={{
                  justifyContent: "flex-start",
                  alignSelf: "flex-start",
                  display: inappWebview ? "none" : "flex",
                }}
                disabled={props.disabled}
                onPress={() => {
                  setRememberMe(!rememberMe);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 12,
                    justifyContent: "flex-start",
                    alignSelf: "flex-start",
                    marginLeft: -8,
                  }}
                >
                  <Checkbox status={rememberMe ? "checked" : "unchecked"} />
                  <Text style={{ fontSize: 14 }}>Duy trì đăng nhập</Text>
                </View>
              </TouchableOpacity>
            )}
            <Button
              disabled={props.disabled}
              onPress={props.onSubmit}
              style={{
                marginTop:
                  props.rememberMeEnabled && !props.usernameHidden ? 12 : 24,
              }}
              mode="contained"
            >
              {"Đăng nhập".toUpperCase()}
            </Button>
          </>
        )}
        {
          <TouchableOpacity
            onPress={() => {
              props.onForgetPassword();
            }}
            style={{
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <Text
              variant="titleSmall"
              style={{
                marginTop: 16,
                color:
                  !props.noPassword || !props.usernameHidden
                    ? "#5c5c5c"
                    : "rgb(30, 136, 229)",
              }}
            >
              {!props.noPassword
                ? "Quên mật khẩu?"
                : "Đăng nhập bằng số điện thoại"}
            </Text>
          </TouchableOpacity>
        }
        {!props.usernameHidden &&
          props.social?.displayInfo &&
          props.social.providers &&
          props.social.providers.length > 0 && (
            <>
              <SocialIdpDivider>hoặc đăng nhập bằng</SocialIdpDivider>
              {props.social.providers.map((e) => {
                return (
                  <SocialButton
                    onClick={() => {
                      window.location.href = e.loginUrl;
                    }}
                  >
                    {e.displayName}
                  </SocialButton>
                );
              })}
            </>
          )}
      </View>
      {showQR && props.loginQR != null && (
        <>
          <View
            style={{
              top: 0,
              bottom: 0,
              width: 1,
              marginLeft: 24,
              marginRight: 24,
              backgroundColor: "#d1d1d1",
            }}
          />
          <LoginQr
            realm={props.realm}
            qr={props.loginQR}
            onSubmit={() => {
              setUsername("");
              setPassword("");
              setLoginByQR(true);
              setTimeout(() => {
                props.onSubmit();
              }, 60);
            }}
          />
        </>
      )}
    </View>
  );
};

const SocialButton = styled.div`
  cursor: pointer;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #2477f2;
  text-align: center;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #2477f2;
  &:hover {
    border-color: #82bdfa;
    color: #fff;
    font-weight: 600;
    background-color: #1e88e5;
  }
`;

const LoginQr = (props: {
  realm: string;
  qr?: string | undefined;
  onSubmit: () => void;
}) => {
  React.useEffect(() => {
    var checkQRInterval: any;
    if (props.qr != null) {
      checkQRInterval = setInterval(() => {
        checkQR(props.qr ?? "").then((result) => {
          if (result) {
            clearInterval(checkQRInterval);
            props.onSubmit();
          }
        });
      }, 2000);
    }

    return () => {
      if (checkQRInterval != null) {
        clearInterval(checkQRInterval);
      }
    };
  }, []);

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: 150,
          height: 150,
          padding: 8,
          borderWidth: 1,
          borderColor: "#d9d9d9",
        }}
      >
        <QRCode
          style={{
            height: "auto",
            maxWidth: "100%",
            width: "100%",
          }}
          value={props.qr ?? ""}
          viewBox={`0 0 256 256`}
        />
      </View>
      <Text style={{ marginTop: 16 }} variant="titleMedium">
        Đăng nhập bằng QR Code
      </Text>
      {/* <Text variant="bodyMedium">Sử dụng ứng dụng Hue-S để quét mã QR</Text> */}
    </View>
  );
};

export default Login;
