import { createRoot } from "react-dom/client";
import { StrictMode, lazy, Suspense } from "react";
import { kcContext } from "./KcApp/kcContext";
import Toast from "react-native-toast-message";

const App = lazy(() => import("./App"));
const KcApp = lazy(() => import("./KcApp"));

// if (kcContext !== undefined) {
//   console.log(kcContext);
// }

createRoot(document.getElementById("root")!).render(
  <div>
    {/* <link rel="stylesheet"  href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.14.1/react-datepicker.min.css" /> */}
    <style type="text/css">
      {`
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require("react-native-vector-icons/Fonts/MaterialIcons.ttf")}) format('truetype');
        }
        @font-face {
          font-family: 'Ionicons';
          src: url(${require("react-native-vector-icons/Fonts/Ionicons.ttf")}) format('truetype');
        }

        @font-face {
          font-family: 'FontAwesome';
          src: url(${require("react-native-vector-icons/Fonts/FontAwesome.ttf")}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")}) format('truetype');
        }

      `}
    </style>
    <Suspense>
      {kcContext === undefined ? <App /> : <KcApp kcContext={kcContext} />}
    </Suspense>
    <div
      style={{
        position: "fixed",
        height: "100vh",
        width: "100%",
        pointerEvents: "none",
        top: 0,
      }}
    >
      <Toast  />
    </div>
  </div>
);
