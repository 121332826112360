/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
// This is a copy paste from https://github.com/InseeFrLab/keycloakify/blob/main/src/lib/components/Register.tsx
// It is now up to us to implement a special behavior to leverage the non standard authorizedMailDomains
// provided by the plugin: https://github.com/micedre/keycloak-mail-whitelisting installed on our keycloak server.
// Note that it is no longer recommended to use register.ftl, it's best to use register-user-profile.ftl
// See: https://docs.keycloakify.dev/realtime-input-validation

import React, { memo } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import { Button, Portal, Text } from "react-native-paper";
import Template, { isDev, StateDialog } from "components/Template";
import { View } from "react-native";
import {
  AddressSelectionData,
  GenderSelection,
  KcAddressSelection,
  KcAvatarInput,
  KcDateInputPicker,
  KcTextInput,
} from "components/KcFormInput";
import {
  BaseDomainError,
  checkResgister,
  RegisterData,
  sendUserProfileForm,
} from "api";
import moment from "moment";
import { OTPInput } from "./PhoneOTPLogin";
import { to } from "evt";
import { update } from "lodash";
import { InfoNode } from "./Login";

type KcContext_Register = Extract<KcContext, { pageId: "register.ftl" }>;

const Register = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_Register; i18n: I18n } & KcProps) => {
    if (kcContext.inAppReview) {
      return <InReviewRegister kcContext={kcContext} i18n={i18n} {...props} />;
    } else {
      return <MainRegister kcContext={kcContext} i18n={i18n} {...props} />;
    }
  }
);

const InReviewRegister = ({
  kcContext,
  i18n,
  ...props
}: { kcContext: KcContext_Register; i18n: I18n } & KcProps) => {
  const [step, setStep] = React.useState(1);

  const formRef = React.useRef<HTMLFormElement>(null);
  const [userToken, setUserToken] = React.useState<string | null>(null);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [birthDate, setBirthDate] = React.useState<Date | null>(null);
  const [personIdentity, setPersonIdentity] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);
  const validateForm = (): string[] => {
    var messages: string[] = [];
    if (name.length == 0) {
      messages.push("- Chưa nhập họ tên");
    }
    if (password.length == 0) {
      messages.push("- Chưa nhập mật khẩu");
    }
    if (phone.length == 0) {
      messages.push("- Chưa nhập số điện thoại");
    } else if (phone.length != 10 || !phone.startsWith("0")) {
      messages.push("- Số điện thoại không hợp lệ");
    }
    return messages;
  };
  return (
    <Template
      {...{ kcContext, i18n, ...props }}
      doFetchDefaultThemeResources={true}
      headerNode={"Đăng ký tài khoản"}
      maxWidth={520}
      onBack={() => {
        if (step > 1) {
          setStep(step - 1);
        } else {
          window.location.href = kcContext.url.loginUrl;
        }
      }}
      backLabel={step > 1 ? "Quay lại" : "Đăng nhập"}
      formNode={
        <>
          {userToken != null && (
            <form
              method="POST"
              ref={formRef}
              action={kcContext.url.registrationAction}
              style={{ height: 1 }}
            >
              <input
                hidden={true}
                name={"token"}
                type={"text"}
                value={userToken}
              />
            </form>
          )}
          {step == 1 && userToken == null && (
            <>
              <KcTextInput
                require={true}
                placeholder="Nhập đầy đủ họ tên khai sinh"
                first={true}
                tabIndex={1}
                // disabled={props.disabled}
                defaultValue={name}
                onChangeText={(value) => {
                  setName(value.trim());
                }}
                title="Họ và tên"
              />
              <KcDateInputPicker
                require={false}
                placeholder="ngày/tháng/năm"
                zIndex={12}
                tabIndex={2}
                defaultValue={
                  birthDate != null
                    ? moment(birthDate).format("DD/MM/YYYY")
                    : ""
                }
                onChange={(value) => {
                  setBirthDate(value);
                }}
                title="Ngày sinh"
              />
              <KcTextInput
                require={false}
                // disabled={props.disabled}
                placeholder="Nhập số CCCD"
                maxLength={12}
                tabIndex={3}
                defaultValue={personIdentity}
                title="Định danh công dân"
                keyboardType="numeric"
                onChangeText={(value) => {
                  setPersonIdentity(value.trim());
                }}
              />
              <KcTextInput
                require={true}
                tabIndex={4}
                defaultValue={phone}
                placeholder="Nhập số di động"
                helper="Mã xác thực OTP sẽ gửi về số này"
                title="Số điện thoại"
                onChangeText={(value) => {
                  setPhone(value.trim());
                }}
                keyboardType="numeric"
              />
              <KcTextInput
                // disabled={props.disabled}
                require={true}
                defaultValue={password}
                placeholder="Đặt mật khẩu đăng nhập"
                title="Mật khẩu"
                onChangeText={(value) => {
                  setPassword(value.trim());
                }}
                secureTextEntry
              />
              <Button
                // disabled={props.disabled}
                onPress={async () => {
                  var errors = validateForm();
                  if (errors.length != 0) {
                    setState({
                      loading: false,
                      message: errors.join("\n"),
                    });
                  } else {
                    try {
                      setState({
                        loading: true,
                      });
                      const matchInfo = await checkResgister(
                        personIdentity,
                        name,
                        birthDate,
                        phone
                      );
                      setState(null);
                      setStep(2);
                    } catch (e) {
                      var error = "Có lỗi xảy ra. Vui lòng thử lại";
                      if (e instanceof BaseDomainError) {
                        error = e.message;
                      }
                      setState({
                        loading: false,
                        message: error,
                      });
                    }
                  }
                }}
                style={{ marginTop: 32 }}
                mode="contained"
              >
                {"Kiểm tra thông tin".toUpperCase()}
              </Button>
              <Text style={{ marginTop: 24 }}>
                {" "}
                Thông tin có dấu
                <Text style={{ color: "red" }}> * </Text>bắt buộc phải nhập
              </Text>
            </>
          )}

          {step == 2 && userToken == null && (
            <OTPInput
              disabled={state != null}
              submitLabel={"Đăng ký"}
              phone={phone ?? ""}
              onSubmit={async (otp) => {
                try {
                  setState({
                    loading: true,
                  });
                  var registerData: RegisterData;
                  var citizenCard = "";
                  var identityCard = "";

                  if (personIdentity.length == 9) {
                    identityCard = personIdentity;
                  } else if (personIdentity.length == 12) {
                    citizenCard = personIdentity;
                  }
                  registerData = {
                    name: name,
                    phone: phone,
                    birthDate: birthDate,
                    password: password,
                    gender: 1,
                    citizenCard: citizenCard,
                    identityCard: identityCard,
                  };
                  var token = await sendUserProfileForm({
                    ...registerData,
                    otp: otp,
                  });
                  setState({
                    loading: false,
                    message: "Đăng ký tài khoản thành công",
                  });
                  setTimeout(() => {
                    setUserToken(token);
                    setTimeout(() => {
                      formRef.current?.submit();
                    }, 100);
                  }, 400);
                } catch (e) {
                  var error =
                    "Có lỗi xảy ra khi đăng ký tài khoản. Vui lòng thử lại.";
                  if (e instanceof BaseDomainError) {
                    error = e.message;
                  }
                  setState({
                    loading: false,
                    message: error,
                  });
                }
              }}
            />
          )}

          {state != null && (
            <Portal>
              <StateDialog
                onCancel={
                  state?.loading == true
                    ? undefined
                    : () => {
                        setState(null);
                      }
                }
                state={state?.loading == true ? "loading" : "alert"}
                visible={true}
                message={state?.message ?? ""}
              />
            </Portal>
          )}
        </>
      }
    />
  );
};

const MainRegister = ({
  kcContext,
  i18n,
  ...props
}: { kcContext: KcContext_Register; i18n: I18n } & KcProps) => {
  const [step, setStep] = React.useState(1);
  const [userToken, setUserToken] = React.useState<string | null>(null);

  const [accountIdentity, setAccountIdentity] =
    React.useState<AccountIdentity | null>(null);
  const [otherData, setOtherData] = React.useState<OtherAccountData | null>(
    null
  );

  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  return (
    <Template
      {...{ kcContext, i18n, ...props }}
      doFetchDefaultThemeResources={true}
      headerNode={"Đăng ký tài khoản"}
      maxWidth={520}
      onBack={() => {
        if (step > 1) {
          setStep(step - 1);
        } else {
          window.location.href = kcContext.url.loginUrl;
        }
      }}
      backLabel={step > 1 ? "Quay lại" : "Đăng nhập"}
      formNode={
        <>
          {userToken != null && (
            <form
              method="POST"
              ref={formRef}
              action={kcContext.url.registrationAction}
              style={{ height: 1 }}
            >
              <input
                hidden={true}
                name={"token"}
                type={"text"}
                value={userToken}
              />
            </form>
          )}
          {userToken == null && (
            <View
              style={
                step != 1
                  ? { height: 0, overflow: "hidden", display: "none" }
                  : {}
              }
            >
              <IdentityForm
                disabled={step != 1}
                onSubmit={(data) => {
                  setAccountIdentity(data);
                  setStep(step + 1);
                }}
              />
            </View>
          )}
          {userToken == null && (
            <View
              style={
                step != 2
                  ? { height: 0, overflow: "hidden", display: "none" }
                  : {}
              }
            >
              <OtherDataForm
                brief={false}
                disabled={step != 2}
                onSubmit={(data) => {
                  setOtherData(data);
                  setStep(step + 1);
                }}
              />
            </View>
          )}
          {step == 3 && userToken == null && (
            <OTPInput
              disabled={state != null}
              submitLabel={"Đăng ký"}
              phone={accountIdentity?.phone ?? ""}
              onSubmit={async (otp) => {
                try {
                  setState({
                    loading: true,
                  });
                  var registerData: RegisterData;
                  if (accountIdentity?.matchInfo) {
                    registerData = {
                      name: accountIdentity!.name,
                      idNumber: accountIdentity!.citizenId,
                      phone: accountIdentity!.phone,
                      birthDate: accountIdentity!.birthDate,
                      avatar: otherData!.avatar,
                      password: otherData!.password,
                    };
                  } else {
                    registerData = {
                      name: accountIdentity!.name,
                      phone: accountIdentity!.phone,
                      birthDate: accountIdentity!.birthDate,
                      avatar: otherData!.avatar,
                      password: otherData!.password,
                      gender: otherData!.gender,
                      citizenCard: accountIdentity?.citizenId,
                      residentAddress: otherData!.residentAddress,
                    };
                  }
                  var token = await sendUserProfileForm({
                    ...registerData,
                    otp: otp,
                  });
                  setState({
                    loading: false,
                    message: "Đăng ký tài khoản thành công",
                  });
                  setTimeout(() => {
                    setUserToken(token);
                    setTimeout(() => {
                      formRef.current?.submit();
                    }, 100);
                  }, 400);
                } catch (e) {
                  var error =
                    "Có lỗi xảy ra khi đăng ký tài khoản. Vui lòng thử lại.";
                  if (e instanceof BaseDomainError) {
                    error = e.message;
                  }
                  setState({
                    loading: false,
                    message: error,
                  });
                }
              }}
            />
          )}
          <Portal>
            {state != null && (
              <StateDialog
                onCancel={
                  state?.loading == true
                    ? undefined
                    : () => {
                        setState(null);
                      }
                }
                state={state?.loading == true ? "loading" : "alert"}
                visible={true}
                message={state?.message ?? ""}
              />
            )}
          </Portal>
        </>
      }
      infoNode={<InfoNode />}
    />
  );
};

const OtherDataForm = (props: {
  disabled: boolean;
  brief: boolean;
  onSubmit: (data: OtherAccountData) => void;
  data?: OtherAccountData | null;
}) => {
  const [avatar, setAvatar] = React.useState<File | null>(null);
  const [password, setPassword] = React.useState("");
  const [gender, setGender] = React.useState<number | null>(null);
  const [residentAddress, setResidentAddress] =
    React.useState<AddressSelectionData | null>(null);
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);
  const validateForm = (): string[] => {
    if (props.brief) {
      return [];
    } else {
      var messages: string[] = [];
      if (
        residentAddress == null ||
        residentAddress.provinceId == null ||
        residentAddress.districtId == null ||
        residentAddress.wardId == null 
        // || residentAddress.address == null ||
        // residentAddress.address.trim().length == 0
      ) {
        messages.push("- Thông tin thường trú chưa đầy đủ");
      }
      if (gender == null) {
        messages.push("- Chưa chọn giới tính");
      }
      return messages;
    }
  };
  return (
    <>
      <KcAvatarInput
        first={true}
        title="Ảnh khuôn mặt"
        onChange={(value) => {
          setAvatar(value);
        }}
      />
      {!props.brief && (
        <GenderSelection
          require={true}
          onchange={(gender) => {
            setGender(gender);
          }}
          // defaultValue={
          //   this.props?.data?.gender ? this.props?.data?.gender + "" : undefined
          // }
          title="Giới tính"
        />
      )}
      {!props.brief && (
        <KcAddressSelection
          defaultValue={
            props.data?.residentAddress ?? {
              provinceId: "86",
              address: null,
              districtId: null,
              wardId: null,
            }
          }
          showAddressDetail={false}
          onChange={(value) => {
            setResidentAddress(value);
            // this.registerData.residentAddress = value;
          }}
          title="Địa chỉ thường trú"
          zIndex={10}
          require={true}
        />
      )}
      <KcTextInput
        disabled={props.disabled}
        require={true}
        placeholder="Đặt mật khẩu đăng nhập"
        title="Mật khẩu"
        // defaultValue={this.props.data?.password ?? ""}
        onChangeText={(value) => {
          setPassword(value.trim());
        }}
        secureTextEntry
      />

      <Button
        disabled={props.disabled}
        onPress={async () => {
          const errors = validateForm();
          if (errors.length != 0) {
            setState({
              loading: false,
              message: errors.join("\n"),
            });
          } else {
            setState({
              loading: true,
            });
            try {
              setState(null);
              props.onSubmit({
                avatar: avatar,
                gender: gender,
                password: password,
                residentAddress: residentAddress,
              });
            } catch (e) {
              var error = "Có lỗi xảy ra. Vui lòng thử lại";
              if (e instanceof BaseDomainError) {
                error = e.message;
              }
              setState({
                loading: false,
                message: error,
              });
            }
          }
        }}
        style={{ marginTop: 32 }}
        mode="contained"
      >
        {"Đăng ký".toUpperCase()}
      </Button>
      {props.brief && (
        <View
          style={{ alignContent: "center", alignItems: "center", marginTop: 8 }}
        >
          <Text style={{ color: "#757575" }}>
            Các thông tin trên không bắt buộc nhập
          </Text>
        </View>
      )}
      <Portal>
        {state != null && (
          <StateDialog
            onCancel={
              state?.loading == true
                ? undefined
                : () => {
                    setState(null);
                  }
            }
            state={state?.loading == true ? "loading" : "alert"}
            visible={true}
            message={state?.message ?? ""}
          />
        )}
      </Portal>
    </>
  );
};

const IdentityForm = (props: {
  disabled: boolean;
  onSubmit: (data: AccountIdentity) => void;
  data?: AccountIdentity | null;
}) => {
  const [name, setName] = React.useState(props.data?.name ?? "");
  const [phone, setPhone] = React.useState(props.data?.phone ?? "");
  const [birthDate, setBirthDate] = React.useState<Date | null>(
    props.data?.birthDate ?? null
  );
  const [citizenId, setCitizenId] = React.useState(props.data?.citizenId ?? "");
  const [state, setState] = React.useState<{
    loading: boolean;
    message?: string | undefined;
  } | null>(null);
  const [confirm, setConfirm] = React.useState(false);

  const validateForm = (): string[] => {
    var messages: string[] = [];
    if (name.length == 0) {
      messages.push("- Chưa nhập họ tên");
    }
    if (phone.length == 0) {
      messages.push("- Chưa nhập số điện thoại");
    } else if (phone.length != 10 || !phone.startsWith("0")) {
      messages.push("- Số điện thoại không hợp lệ");
    }
    if (birthDate == null) {
      messages.push("- Chưa nhập ngày sinh");
    }
    if (citizenId.length == 0) {
      messages.push("- Chưa nhập số căn cước công dân");
    } else if (citizenId.length != 12) {
      messages.push("- Số căn cước công dân không hợp lệ");
    }
    return messages;
  };
  return (
    <>
      <KcTextInput
        require={true}
        placeholder="Nhập đầy đủ họ tên khai sinh"
        first={true}
        tabIndex={1}
        disabled={props.disabled}
        defaultValue={props.data?.name}
        onChangeText={(value) => {
          setName(value.trim());
        }}
        title="Họ và tên"
      />
      <KcDateInputPicker
        require={true}
        placeholder="ngày/tháng/năm"
        zIndex={12}
        tabIndex={2}
        defaultValue={
          props.data?.birthDate
            ? moment(props.data.birthDate!).format("DD/MM/YYYY")
            : undefined
        }
        onChange={(value) => {
          setBirthDate(value);
        }}
        title="Ngày sinh"
      />
      <KcTextInput
        require={true}
        disabled={props.disabled}
        placeholder="Nhập 12 số CCCD"
        maxLength={12}
        tabIndex={3}
        title="Căn cước công dân"
        keyboardType="numeric"
        onChangeText={(value) => {
          setCitizenId(value.trim());
        }}
      />
      <KcTextInput
        require={true}
        tabIndex={4}
        maxLength={10}
        placeholder="Nhập số di động"
        helper="Mã xác thực OTP sẽ gửi về số này"
        title="Số điện thoại"
        defaultValue={props?.data?.phone}
        onChangeText={(value) => {
          setPhone(value.trim());
        }}
        keyboardType="numeric"
      />
      <Button
        disabled={props.disabled}
        onPress={async () => {
          var errors = validateForm();
          if (errors.length != 0) {
            setState({
              loading: false,
              message: errors.join("\n"),
            });
          } else {
            try {
              setState({
                loading: true,
              });
              const matchInfo = await checkResgister(
                citizenId,
                name,
                birthDate!,
                phone
              );
              setState(null);
              if (matchInfo) {
                props.onSubmit({
                  birthDate: birthDate ?? new Date(),
                  phone: phone,
                  name: name,
                  citizenId: citizenId,
                  matchInfo: matchInfo,
                });
              } else {
                // setConfirm(true);
                props.onSubmit({
                  birthDate: birthDate ?? new Date(),
                  phone: phone,
                  name: name,
                  citizenId: citizenId,
                  matchInfo: false,
                });
              }
            } catch (e) {
              var error = "Có lỗi xảy ra. Vui lòng thử lại";
              if (e instanceof BaseDomainError) {
                error = e.message;
              }
              setState({
                loading: false,
                message: error,
              });
            }
          }
        }}
        style={{ marginTop: 32 }}
        mode="contained"
      >
        {"Kiểm tra thông tin".toUpperCase()}
      </Button>
      {confirm && (
        <Portal>
          <StateDialog
            onCancel={() => {
              setConfirm(false);
            }}
            // onConfirm={() => {
            //   setConfirm(false);
            //   props.onSubmit({
            //     birthDate: birthDate ?? new Date(),
            //     phone: phone,
            //     name: name,
            //     citizenId: citizenId,
            //     matchInfo: false,
            //   });
            // }}
            // confirmLabel={"Tiếp tục đăng ký"}
            state={"alert"}
            visible={true}
            title={"Xác nhận"}
            message={
              "Không tìm thấy dữ liệu dân cư trùng khớp với các thông tin họ tên, ngày sinh, số định danh mà bạn cung cấp. Bạn có chắc chắn các thông tin trên chính xác không?"
            }
          />
        </Portal>
      )}

      {state != null && (
        <Portal>
          <StateDialog
            onCancel={
              state?.loading == true
                ? undefined
                : () => {
                    setState(null);
                  }
            }
            state={state?.loading == true ? "loading" : "alert"}
            visible={true}
            message={state?.message ?? ""}
          />
        </Portal>
      )}
    </>
  );
};

/*
 */

type AccountIdentity = {
  phone: string;
  name: string;
  citizenId: string;
  birthDate: Date;
  matchInfo: boolean;
};

type OtherAccountData = {
  gender: number | null;
  residentAddress: AddressSelectionData | null;
  password: string | null;
  avatar: File | null;
};
export default Register;
